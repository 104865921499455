import { Icon } from "components/icon/Icon";
import { Button } from "components/ui/button";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipArrow,
} from "components/ui/tooltip";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "utils";

interface ExpandableInfoTooltipProps {
  text?: string;
  moreText?: string | ReactNode;
  testId?: string;
  side?: "top" | "bottom";
  children?: ReactNode;
  triggerClassName?: string;
  contentClassName?: string;
  align?: "start" | "center" | "end";
}

export const ExpandableInfoTooltip = ({
  text,
  moreText,
  testId,
  triggerClassName = "",
  side = "bottom",
  children,
  contentClassName = "",
  align = "center",
}: ExpandableInfoTooltipProps) => {
  const [openMore, setOpenMore] = useState(false);
  const { t } = useTranslation(["general"]);
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <span
          className={cn(
            "text-secondary-400 hover:text-secondary-500 shrink-0",
            "transition-opacity duration-200",
            triggerClassName,
          )}
          data-testid={testId}
        >
          <Icon name="info" size="medium" />
        </span>
      </TooltipTrigger>
      <TooltipContent
        side={side}
        align={align}
        className={cn(
          "text-left shadow-tooltip text-neutral-600 whitespace-normal z-10 border-none gap-2 py-2 leading-relaxed max-w-[25rem]",
          contentClassName,
        )}
        sideOffset={6}
      >
        <TooltipArrow
          className="fill-white drop-shadow-sm"
          width={12}
          height={6}
        />
        {children || (
          <p className="flex justify-start items-start text-left w-full mt-2">
            {text}
          </p>
        )}
        <div className="flex justify-start items-start text-left w-full">
          {moreText && (
            <div className="flex flex-col gap-2 items-start">
              {openMore && <span className="text-xs">{moreText}</span>}
              <Button
                variant="link"
                className="p-0 flex text-xs text-primary-400 hover:text-primary-500"
                onClick={() => setOpenMore(!openMore)}
              >
                <Icon
                  name={openMore ? "arrow-up" : "arrow-down"}
                  size="small"
                />
                {openMore
                  ? t("general:buttons.learnLess")
                  : t("general:buttons.learnMore")}
              </Button>
            </div>
          )}
        </div>
      </TooltipContent>
    </Tooltip>
  );
};
