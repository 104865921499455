import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "components/button";
import { InfoTooltip } from "components/InfoTooltip";
import { useFeatureFlags } from "contexts/FeatureFlagContext";
import { DatabaseCodes } from "./DatabaseCodes";
import { GeneratedCodes } from "./GeneratedCode";

export interface ThemaTabProps {
  title: string;
  key: "generated" | "database";
  content: React.ReactNode;
}

export const ThemaTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("themaTab") || "generated";
  const { t } = useTranslation(["productDetails"]);

  const { features } = useFeatureFlags();
  const themaVersion = features.operationalThemaVersion;

  const tabs: ThemaTabProps[] = [
    {
      title: t("thema.tabs.database"),
      key: "database",
      content: <DatabaseCodes />,
    },
    {
      title: t("thema.tabs.generated"),
      key: "generated",
      content: <GeneratedCodes />,
    },
  ];

  const validActiveTab = tabs.find((tab) => tab.key === activeTab)
    ? activeTab
    : "generated";

  const handleTabClick = (tabKey: string) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set("themaTab", tabKey);
    setSearchParams(newSearchParams);
  };

  return (
    <div className="flex flex-col flex-1 gap-2">
      <div className="flex flex-row items-center gap-2 bg-secondary-50 p-2 rounded-xl">
        {tabs.map((tab) => (
          <Button
            key={tab.title}
            label={
              <p className="font-semibold text-sm text-secondary-600">
                {tab.title}
              </p>
            }
            size="small"
            variant={validActiveTab === tab.key ? "tertiary" : "ghost"}
            onClick={() => handleTabClick(tab.key)}
          />
        ))}
        <InfoTooltip
          text={t("thema.version", { version: themaVersion })}
          triggerClassName="!opacity-100 text-secondary-400 ml-auto mr-2"
          side="top"
        />
      </div>
      {tabs.find((tab) => tab.key === validActiveTab)?.content}
    </div>
  );
};
