import { Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import { OrganisationLayout } from "./OrganisationLayout";
import { OrganisationMembersPage } from "./members/OrganisationMembersPage";
import { OrganisationOnixUploadPage } from "./onix/OrganisationOnixUploadPage";
import { OrganisationStatusPage } from "./status/OrganisationStatusPage";

export const OrganisationRoutes = () => {
  return (
    <OrganisationLayout>
      <Routes>
        <Route index element={<Navigate to="members" replace />} />
        <Route path="members" element={<OrganisationMembersPage />} />
        <Route path="onix-upload" element={<OrganisationOnixUploadPage />} />
        <Route path="status" element={<OrganisationStatusPage />} />
      </Routes>
    </OrganisationLayout>
  );
};
