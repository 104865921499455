import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RoutePath } from "shared/constants";
import { Warning } from "components/icon/Warning";
import { Button } from "components/button";

export const MaintenancePage: React.FC = () => {
  const { t } = useTranslation(["general"]);

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-background">
      <div className="mx-auto max-w-2xl text-center">
        <div className="mb-6 flex justify-center">
          <Warning className="h-16 w-16" />
        </div>
        <h1 className="mb-4 text-4xl font-bold text-foreground">
          {t("general:maintenanceTitle")}
        </h1>
        <p className="mb-8 text-lg text-muted-foreground">
          {t("general:maintenanceMessage")}
        </p>
        <Link
          to={RoutePath.ContactUs}
          target="_blank"
          rel="noopener noreferrer"
          data-testid="support-action"
        >
          <Button
            label={t("general:contactUs")}
            variant="tertiary"
            size="small"
            className="!text-sm"
          />
        </Link>
      </div>
    </div>
  );
};

export default MaintenancePage;
