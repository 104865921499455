import debounce from "lodash/debounce";
import {
  FirestoreGenerationStatusCode,
  FirestoreProductDescription,
} from "reedy-data/models";
import { logError } from "shared/services/ErrorReporting";
import { LoadingGenerateDescription } from "./LoadingGenerateDescription";
import { Editor } from "../Editor";
import { EditorWordCount } from "../Editor/EditorWordCount";
import { GenerateBookDescriptionError } from "./GenerateBookDescriptionError";
import { IKeywords } from "../Editor/extensions/Keywords";
import { GenerateBookDescriptionEmpty } from "./GenerateBookDescriptionEmpty";

interface GeneratedDescriptionEditorProps {
  description?: FirestoreProductDescription;
  keywords: IKeywords;
  loading: boolean;
  error?: {
    statusCode?: FirestoreGenerationStatusCode | null;
    message?: string | null;
  };
  onUpdate: (hash: string, updatedText: string) => void;
  onRetry: () => void;
  className?: string;
}
export const GeneratedDescriptionEditor = ({
  description,
  keywords,
  loading,
  error,
  onUpdate,
  onRetry,
  className,
}: GeneratedDescriptionEditorProps) => {
  const handleUpdate = debounce((value: string) => {
    if (!description?.hash) {
      logError("Unable to determine description to update. Hash is undefined");
      return;
    }

    return onUpdate(description.hash, value);
  }, 500);

  if (loading) {
    return <LoadingGenerateDescription />;
  }

  if (error) {
    return (
      <GenerateBookDescriptionError
        onClick={() => onRetry()}
        statusCode={error.statusCode}
      />
    );
  }

  if (!description?.textGenerated) {
    return (
      <GenerateBookDescriptionEmpty
        onClick={() => onRetry()}
        disabled={!description?.text}
      />
    );
  }

  return (
    <Editor
      key={description?.hash}
      initialValue={description?.textGenerated}
      keywords={keywords}
      onChange={handleUpdate}
      className={className}
    >
      <div data-testid="editor-footer">
        <EditorWordCount />
      </div>
    </Editor>
  );
};
