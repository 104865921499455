import { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { cn } from "utils";

export const SettingsLayout = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation("settings");

  return (
    <div className="h-screen grid grid-cols-[244px,1fr] divide-x divide-secondary-100 -mx-16 -my-8">
      <div className="bg-secondary-25 px-4 py-6">
        <div className="sticky -top-2 space-y-1">
          <div className="text-secondary-400 text-xs font-medium ml-1">
            {t("settings:navigation.title")}
          </div>
          <nav className="grid grid-cols-1">
            <NavLink
              to="organisations"
              className={(props) =>
                cn(
                  "p-2 rounded-md text-sm font-medium",
                  props.isActive && "bg-secondary-50",
                )
              }
            >
              {t("settings:navigation.items.organisations.title")}
            </NavLink>
          </nav>
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};
