import { useCallback, useMemo, useState } from "react";
import { Modal } from "components/modal/Modal";
import { useApi } from "hooks/useApi";
import { useDialog } from "hooks/useDialog";
import { useTranslation } from "react-i18next";
import { toast } from "utils/toast";
import { useFeatureFlags } from "contexts/FeatureFlagContext";
import { useAuth } from "contexts/AuthContext";
import { UserRole } from "__generated__/models";
import { getEvaluations } from "integrations/firebase/collections/evaluations";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Button } from "components/button";
import { logError } from "shared/services/ErrorReporting";
import { COMPLETED_STATUSES } from "shared/constants";
import { Tooltip } from "components/tooltip";
import orderBy from "lodash/orderBy";
import { ButtonGroup } from "components/button/ButtonGroup";
import { cn } from "utils";
import { formatDateTime } from "shared/utils";

interface EvaluationModalProps {
  selectedProducts: string[] | "ALL";
}

export const EvaluationModal = ({ selectedProducts }: EvaluationModalProps) => {
  const api = useApi();
  const { userData } = useAuth();
  const { features } = useFeatureFlags();
  const { t } = useTranslation(["books"]);
  const warning = useDialog("evaluation-warning");
  const cancel = useDialog("evaluation-cancel");
  const [requestingEvaluation, setRequestingEvaluation] = useState(false);

  const [evaluations = [], loading] = useCollectionData(
    userData?.organisation.id
      ? getEvaluations(userData?.organisation.id)
      : undefined,
  );

  const sortedEvaluations = useMemo(
    () => orderBy(evaluations, (e) => e.createdAt, "desc"),
    [evaluations],
  );

  const latestActiveEvaluation = useMemo(
    () =>
      sortedEvaluations.find(
        (e) => e.status && !COMPLETED_STATUSES.includes(e.status),
      ),
    [sortedEvaluations],
  );

  const hasActiveEvaluation = Boolean(latestActiveEvaluation);

  const onRunEvaluation = useCallback(async () => {
    try {
      if (requestingEvaluation) {
        return;
      }

      setRequestingEvaluation(true);

      await api.evaluation.evaluate(selectedProducts);

      toast.success(t("books:evaluation.created.success"));

      warning.onClose();
    } catch (e) {
      logError(e);
      toast.error(t("books:evaluation.errorRunningEvaluation"));
    } finally {
      setRequestingEvaluation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts, requestingEvaluation, warning.onClose, api]);

  const onCancelEvaluation = useCallback(async () => {
    try {
      if (!latestActiveEvaluation?.id) {
        return;
      }

      await api.evaluation.cancel(latestActiveEvaluation.id);

      toast.success(t("books:evaluation.modal.cancel.success"));

      cancel.onClose();
    } catch (e) {
      logError(e);
      toast.error(t("books:evaluation.modal.cancel.error"));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestActiveEvaluation, t, api.evaluation, cancel.onClose]);

  const tooltipText = useMemo(() => {
    if (latestActiveEvaluation) {
      const { createdAt, data } = latestActiveEvaluation;

      return t("books:evaluation.actions.run.tooltip.inProgress", {
        count: data.requestedProducts.length,
        createdAt: createdAt && formatDateTime(createdAt),
      });
    }

    if (!selectedProducts.length) {
      return t("books:evaluation.actions.run.tooltip.noBooksSelected");
    }

    return null;
  }, [t, latestActiveEvaluation, selectedProducts]);

  if (
    !features.evaluation ||
    !userData?.roles?.includes(UserRole.SUPER_ADMIN)
  ) {
    return null;
  }

  return (
    <>
      <ButtonGroup>
        <Tooltip content={tooltipText}>
          <Button
            variant="tertiary"
            icon={hasActiveEvaluation ? "loader" : "chart-area"}
            iconClassName={cn(
              hasActiveEvaluation && "animate-spin duration-2000",
            )}
            className="disabled:pointer-events-auto"
            onClick={warning.open}
            disabled={
              loading ||
              requestingEvaluation ||
              hasActiveEvaluation ||
              !selectedProducts.length
            }
          >
            {hasActiveEvaluation
              ? t("books:evaluation.actions.run.text.inProgress")
              : t("books:evaluation.actions.run.text.default")}
          </Button>
        </Tooltip>

        {hasActiveEvaluation && features.cancelEvaluation && (
          <Tooltip content={t("books:evaluation.actions.cancel.tooltip")}>
            <Button
              data-testid="cancel-evaluation-button"
              variant="tertiary"
              icon="x"
              onClick={cancel.open}
            />
          </Tooltip>
        )}
      </ButtonGroup>

      <Modal
        id={warning.id}
        open={warning.isOpen}
        onOpenChange={warning.onOpenChange}
        title={t("books:evaluation.modal.warning.title")}
        description={t("books:evaluation.modal.warning.description")}
        confirmLabel={t("books:evaluation.modal.warning.actions.confirm")}
        cancelLabel={t("books:evaluation.modal.warning.actions.cancel")}
        variant="warning"
        onConfirm={onRunEvaluation}
      />

      <Modal
        id={cancel.id}
        open={cancel.isOpen}
        onOpenChange={cancel.onOpenChange}
        title={t("books:evaluation.modal.cancel.title")}
        description={t("books:evaluation.modal.cancel.description", {
          count: latestActiveEvaluation?.data?.requestedProducts?.length ?? 0,
        })}
        confirmLabel={t("books:evaluation.modal.cancel.actions.confirm")}
        cancelLabel={t("books:evaluation.modal.cancel.actions.cancel")}
        variant="warning"
        onConfirm={onCancelEvaluation}
      />
    </>
  );
};
