export function countWords(text: string) {
  const cleaned = text.replaceAll(/[^A-zÀ-ú0-9-]/gim, " ").trim();

  const words = cleaned
    .split(" ")
    .map((word) => word.trim())
    .filter(Boolean);

  return words.length;
}
