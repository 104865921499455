import { logError } from "shared/services/ErrorReporting";
import { getErrorMessage } from "utils/errorParseHelper";
import { ZodObject, ZodRawShape } from "zod";

type Result<D> = (
  | {
      data: D;
      error: undefined;
    }
  | {
      data: undefined;
      error: unknown;
    }
) & {
  request: Request;
  response: Response;
};

export { getErrorMessage };

export function parseResult<R extends ZodRawShape, D = any>(
  schema: ZodObject<R>,
  result: Result<D>,
  request: any,
) {
  const logWithContext = (e: any) =>
    logError(e, {
      data: {
        ...result,
        requestBody: request,
      },
    });

  if (result.error) {
    const requestErrorMessage = getErrorMessage(result.error);
    logWithContext(requestErrorMessage);
    throw new Error(requestErrorMessage);
  }

  try {
    schema.parse(result.data);
  } catch (parsingError) {
    logWithContext(parsingError);
  }

  return result.data;
}
