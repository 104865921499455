/* eslint-disable no-underscore-dangle */
import { Button } from "components/button";
import { useTranslation } from "react-i18next";
import { SkeletonDescription } from "components/skeletons/SkeletonDescription";
import { useProduct } from "contexts/ProductContext";
import { Generate } from "components/svg/icons/Generate";
import { TicketCategory } from "integrations/zendesk";
import { RoutePath } from "shared/constants";
import { FirestoreGenerationStatusCode } from "__generated__/models";
import { generationErrorMessage } from "../../../../utils";

interface GenerateBookDescriptionErrorProps {
  onClick(): void;

  statusCode?: FirestoreGenerationStatusCode | null;
}

export const GenerateBookDescriptionError = ({
  onClick,
  statusCode,
}: GenerateBookDescriptionErrorProps) => {
  const { t } = useTranslation(["productDetails"]);
  const { product } = useProduct();

  const handleContactSupport = () => {
    const url = new URL(RoutePath.ContactUs, window.location.origin);
    url.searchParams.set("isbn", product?.isbn || "");
    url.searchParams.set(
      "ticketCategory",
      statusCode === FirestoreGenerationStatusCode._402 ||
        statusCode === FirestoreGenerationStatusCode._451
        ? TicketCategory.other
        : TicketCategory.bug,
    );
    window.open(url.toString(), "_blank");
  };

  return (
    <div className="flex flex-col items-center space-y-6 py-40 w-full">
      <div className="relative mt-4">
        <SkeletonDescription
          animated={false}
          className="scale-90 -top-6 absolute -z-10"
        />
        <SkeletonDescription
          animated={false}
          className="scale-95 -top-3 absolute -z-10"
        />
        <SkeletonDescription animated={false} variant="error" />
      </div>

      <div className="text-center space-y-2">
        <p className="text-secondary-900">
          {t("productDetails:bookDescription.tabGenerate.error.header")}
        </p>

        <p className="text-sm text-secondary-500">
          {generationErrorMessage(t, statusCode) ||
            t("productDetails:bookDescription.tabGenerate.error.message", {
              code: statusCode,
            })}
        </p>
      </div>

      <div className="flex flex-row gap-4">
        <Button
          variant="tertiary"
          label={t(
            "productDetails:bookDescription.tabGenerate.actions.contactSupport",
          )}
          disabled={!product}
          onClick={handleContactSupport}
          size="small"
          analyticsId="button_generate_book_description"
          testId="button-open-generate-card"
        />
        <Button
          variant="primary"
          label={
            <div className="flex flex-row items-center">
              <Generate className="w-4 h-4 mr-2" size={14} />
              <p className="text-sm">
                {t("productDetails:bookDescription.tabGenerate.actions.retry")}
              </p>
            </div>
          }
          disabled={!product}
          onClick={onClick}
          size="small"
          analyticsId="button_generate_book_description"
          testId="button-open-generate-card"
        />
      </div>
    </div>
  );
};
