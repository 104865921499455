import { useAnalytics } from "contexts/AnalyticsContext";
import React, { useMemo } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Tabs, TabsList, TabsTrigger } from "components/ui/tabs";

import { BookDescriptionTab } from "pages/ProductDetails/tabs/BookDescriptionTab/BookDescriptionTab";
import { ProductSummary } from "pages/ProductDetails/components/ProductSummary/ProductSummary";

import { useProduct } from "contexts/ProductContext";
import { useProject } from "hooks/useProject";
import { Button } from "components/button";
import { useTranslation } from "react-i18next";
import orderBy from "lodash/orderBy";
import { KeywordsTab } from "pages/ProductDetails/tabs/KeywordsTab";

import { ThemaTab } from "pages/ProductDetails/tabs/ThemaTab/ThemaTab";
import { useFeatureFlags } from "contexts/FeatureFlagContext";
import { ProductDetailsTabBox } from "pages/ProductDetails/components/ProductDetailsTabBox/ProductDetailsTabBox";

export enum PRODUCT_DETAILS_TAB {
  "KEYWORDS" = "keywords",
  "BOOK_DESCRIPTION" = "description",
  "THEMA" = "thema",
}

export const ProductNavigationBar = () => {
  const { gaEvent } = useAnalytics();
  const { product } = useProduct();
  const { t } = useTranslation(["productDetails"]);
  const { features } = useFeatureFlags();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("tab") ?? "keywords";
  const { idProject } = useParams();

  const { products, project } = useProject(idProject);

  const [previousProduct, nextProduct] = useMemo(() => {
    if (!products?.length) {
      return [];
    }

    const ordered = orderBy(products, ({ isbn }) => isbn, "asc");
    const index = ordered.findIndex(({ id }) => id === product?.id);

    const previous = ordered[index - 1];
    const next = ordered[index + 1];

    return [previous?.id, next?.id];
  }, [products, product?.id]);

  const handleClick = (tab: PRODUCT_DETAILS_TAB) => {
    gaEvent({
      type: "click",
      payload: { analytics_id: "change_search_term_tab", text: tab },
    });
  };

  const onTabChange = (tab: string) => {
    searchParams.set("tab", tab);
    setSearchParams(searchParams);
  };

  return (
    <Tabs value={activeTab} onValueChange={onTabChange}>
      <div className="w-full flex flex-row items-start justify-between pb-2">
        <div className="flex flex-col items-start gap-3">
          <ProductSummary
            title={product?.title ?? ""}
            authors={product?.author ?? []}
            isbn={product?.isbn ?? ""}
            productGroupDescription={product?.productGroupDescription ?? ""}
          />
          <TabsList>
            <TabsTrigger
              value="keywords"
              onClick={() => handleClick(PRODUCT_DETAILS_TAB.KEYWORDS)}
            >
              {t("productDetails:tabs.keywords")}
            </TabsTrigger>
            {features.themaCodes && (
              <TabsTrigger
                testId="thema-tab"
                value="thema"
                onClick={() => handleClick(PRODUCT_DETAILS_TAB.THEMA)}
              >
                {t("productDetails:tabs.thema")}
              </TabsTrigger>
            )}
            <TabsTrigger
              testId="description-tab"
              onClick={() => handleClick(PRODUCT_DETAILS_TAB.BOOK_DESCRIPTION)}
              value="description"
            >
              {t("productDetails:tabs.description")}
            </TabsTrigger>
          </TabsList>
        </div>
        <div className="gap-4 flex flex-row">
          {project ? (
            <Link
              aria-disabled={!previousProduct}
              data-testid="previous-product-button"
              to={
                previousProduct
                  ? `/projects/${project?.id}/product/${previousProduct}?tab=${activeTab}`
                  : `?tab=${activeTab}`
              }
            >
              <Button
                variant="tertiary"
                disabled={!previousProduct}
                analyticsId="button_previous_product"
                icon="arrow-left"
                iconPosition="left"
                spanClassName="hidden lg:block"
                label={t("productDetails:header.previous")}
              />
            </Link>
          ) : null}

          {project ? (
            <Link
              aria-disabled={!nextProduct}
              data-testid="next-product-button"
              to={
                nextProduct
                  ? `/projects/${project?.id}/product/${nextProduct}?tab=${activeTab}`
                  : `?tab=${activeTab}`
              }
            >
              <Button
                variant="tertiary"
                disabled={!nextProduct}
                analyticsId="button_next_product"
                icon="arrow-right"
                iconPosition="right"
                spanClassName="hidden lg:block"
                label={t("productDetails:header.next")}
              />
            </Link>
          ) : null}
        </div>
      </div>
      <ProductDetailsTabBox value="keywords">
        <KeywordsTab />
      </ProductDetailsTabBox>
      <ProductDetailsTabBox value="thema">
        <ThemaTab />
      </ProductDetailsTabBox>
      <ProductDetailsTabBox value="description">
        <BookDescriptionTab />
      </ProductDetailsTabBox>
    </Tabs>
  );
};
