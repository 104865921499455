import { useState, useEffect } from "react";

import { useProjects } from "pages/Projects/contexts/ProjectsContext";
import { IProject } from "integrations/firebase/collections";
import { useProducts } from "./useProducts";

export const useProject = (idProject?: string) => {
  const projectsHook = useProjects();
  const { getProject } = projectsHook;
  const [errorProjectRead, setErrorProjectRead] = useState<string>();

  const [project, setProject] = useState<IProject | null>(null);

  useEffect(() => {
    const loadProject = async () => {
      if (!idProject) {
        setErrorProjectRead(undefined);
        setProject(null);
        return;
      }
      try {
        setErrorProjectRead(undefined);
        const loadedProject = await getProject(idProject);
        setProject(loadedProject || null);
      } catch (e) {
        setErrorProjectRead("Cannot load project");
      }
    };
    loadProject();
  }, [getProject, idProject]);

  const {
    products,
    isLoadingRead: isLoadingProducts,
    errorRead: errorProductsRead,
  } = useProducts(project?.productIds);

  return {
    project,
    products,
    isLoadingProducts,
    errorProductsRead,
    errorProjectRead,
    ...projectsHook,
  };
};
