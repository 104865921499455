import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { GripVertical } from "lucide-react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button } from "components/button";
import { Input } from "components/ui/input";
import { cn } from "utils";
import { ListItem } from "components/List/ListItem";
import { InfoTooltip } from "components/InfoTooltip";

type SortableListItemProps = {
  index: number;
  id: string;
  value: string;
  description?: string;
  infoText?: string;
  onRemove: () => void;
  onChange?: (newVal: string) => void;
};

export const SortableListItem: FC<SortableListItemProps> = ({
  value,
  id,
  index,
  infoText,
  description,
  onRemove,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(value);
  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id, disabled: isEditing });

  const handleInputKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== "Enter" || !onChange) {
        return;
      }

      e.preventDefault();
      setIsEditing(false);

      if (newValue && value !== newValue) {
        onChange(newValue);
      }
    },
    [onChange, newValue, value],
  );

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value);
  }, []);

  const handleValueClick = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onBlur = useCallback(() => {
    if (!onChange) return;

    setIsEditing(false);

    if (newValue && value !== newValue) {
      onChange(newValue);
    }
  }, [newValue, value, onChange]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <ListItem
        className={cn(isDragging && "opacity-40")}
        testId={`sortable-item-${id}`}
      >
        <div className="flex flex-row w-full items-center" {...attributes}>
          <div
            {...listeners}
            ref={setActivatorNodeRef}
            className="h-5 min-w-6 flex items-center cursor-grab"
            role="button"
            aria-label={`Drag handle for item ${index + 1}`}
          >
            <GripVertical
              className={cn(
                "hidden h-4 w-4 -ml-1",
                !isEditing && "group-hover:block",
              )}
            />
            <p
              className={cn(
                "block text-sm text-secondary-400",
                !isEditing && "group-hover:hidden",
              )}
            >
              {index + 1}.
            </p>
          </div>

          {isEditing ? (
            <Input
              ref={inputRef}
              value={newValue}
              onKeyDown={handleInputKeyDown}
              onChange={handleInputChange}
              onBlur={onBlur}
              autoFocus
              className="text-sm"
            />
          ) : (
            <button
              type="button"
              disabled={!onChange}
              className="text-sm flex flex-row flex-grow gap-x-2 text-left cursor-pointer overflow-hidden"
              onClick={handleValueClick}
            >
              <p className="text-secondary-800 truncate">{value}</p>
              <div className="flex items-center min-w-0 flex-1">
                <p className="text-sm text-secondary-400 truncate">
                  {description}
                </p>
              </div>
            </button>
          )}

          <div className="flex items-center gap-1">
            {infoText && <InfoTooltip text={infoText} />}
            {!isEditing && (
              <Button
                label=""
                variant="ghost"
                disabled={isEditing}
                icon="x"
                onClick={onRemove}
                className="p-0 hidden group-hover:block"
                analyticsId="button_remove_final_keyword"
                testId={`remove-${id}`}
              />
            )}
          </div>
        </div>
      </ListItem>
    </div>
  );
};
