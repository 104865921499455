import { Client } from "@hey-api/client-fetch";
import {
  exportProducts,
  generateProductDescription,
  generateProductDescriptionBulk,
  generateProductKeywords,
  generateProductKeywordsBulk,
  generateProductThema,
  zExportProductsResponse,
  zGenerateProductDescriptionBulkResponse,
  zGenerateProductDescriptionResponse,
  zGenerateProductKeywordsBulkResponse,
  zGenerateProductKeywordsResponse,
  zGenerateProductThemaResponse,
} from "__generated__/api";
import { parseResult } from "../parseHelper";

export interface DescriptionType {
  textType: string;
  audience: string;
}

export class ProductsApi {
  constructor(private client: Client) {}

  async exportProducts(ids: string[]) {
    if (ids.length === 0) {
      throw new Error("Product IDs array cannot be empty");
    }

    const response = await exportProducts({
      body: { productIds: ids },
      client: this.client,
    });

    const result = parseResult(zExportProductsResponse, response, {});

    return result?.data;
  }

  async generateDescription(
    productId: string,
    options: {
      hash: string;
      targetGroup: string | null;
      specialRequest: string | null;
    },
  ) {
    const response = await generateProductDescription({
      path: { identifier: productId },
      body: {
        hash: options.hash,
        targetGroup: options.targetGroup,
        specialRequest: options.specialRequest,
      },
      client: this.client,
    });

    const result = parseResult(
      zGenerateProductDescriptionResponse,
      response,
      {},
    );

    return result?.data;
  }

  async generateDescriptionsBulk(
    productIds: string[],
    descriptionTypes: DescriptionType[],
  ) {
    const response = await generateProductDescriptionBulk({
      body: { identifiers: productIds, filters: descriptionTypes },
      client: this.client,
    });

    const result = parseResult(
      zGenerateProductDescriptionBulkResponse,
      response,
      {},
    );

    return result?.data;
  }

  async generateKeywords(productId: string) {
    const response = await generateProductKeywords({
      path: { identifier: productId },
      body: { generate: true },
      client: this.client,
    });

    const result = parseResult(zGenerateProductKeywordsResponse, response, {});

    return result?.data;
  }

  async generateKeywordsBulk(productIds: string[], autoFill?: boolean) {
    const response = await generateProductKeywordsBulk({
      body: {
        generate: true,
        identifiers: productIds,
        autoFill,
      },
      client: this.client,
    });

    const result = parseResult(
      zGenerateProductKeywordsBulkResponse,
      response,
      {},
    );

    return result?.data;
  }

  async generateThema(productId: string) {
    const response = await generateProductThema({
      path: { identifier: productId },
      client: this.client,
    });

    const result = parseResult(zGenerateProductThemaResponse, response, {});

    return result?.data;
  }

  async autofillKeywords(productId: string) {
    const response = await generateProductKeywords({
      path: { identifier: productId },
      body: { autoFill: true },
      client: this.client,
    });

    const result = parseResult(zGenerateProductKeywordsResponse, response, {});

    return result?.data;
  }
}
