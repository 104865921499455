import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Label } from "components/ui/label";
import { SharedDataSchema } from "__generated__/models";
import { GENERAL_AUDIENCE_TARGET } from "shared/constants/misc";

export interface DetailsGenerateBookDescriptionProps {
  selectedTarget: string | null;
  setSelectedTarget: (selectedTarget: string | null) => void;
}

export const SelectedTargetsSelect: React.FC<
  DetailsGenerateBookDescriptionProps
> = ({ selectedTarget, setSelectedTarget }) => {
  const { t } = useTranslation(["productDetails"]);
  const { organisation } = useAuth();
  let targets = organisation?.targets;

  if (!targets) {
    targets = [...SharedDataSchema.properties.defaultTargetGroups.example];
  }

  const generalAudienceTarget = {
    label: t(
      "productDetails:bookDescription.tabGenerate.detailsGenerate.targetGeneralAudience",
    ),
    value: GENERAL_AUDIENCE_TARGET,
  };
  const targetMap = [
    generalAudienceTarget,
    ...targets.map((target) => ({ label: target, value: target })),
  ];

  return (
    <div className="grid gap-3">
      <Label className="text-sm">
        {t(
          "productDetails:bookDescription.tabGenerate.detailsGenerate.targetGroup",
        )}
      </Label>
      <Select onValueChange={setSelectedTarget} value={selectedTarget || ""}>
        <SelectTrigger aria-label="target-groups">
          <SelectValue
            placeholder={t(
              "productDetails:bookDescription.tabGenerate.detailsGenerate.targetGroupPlaceholder",
            )}
          />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {targetMap.map((target) => (
              <SelectItem
                key={target.value}
                value={target.value}
                testId={target.value}
              >
                {target.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};
