import * as React from "react";
import { cn } from "utils";
import { EmptyListVertical } from "components/EmptyList/EmptyListVertical";
import { EmptyListProps } from "components/EmptyList/EmptyListTypes";

export interface BasePanelProps {
  title: string;
  headerClassName?: string;
  contentClassName?: string;
  className?: string;
  headerAction?: React.ReactNode;
  footer?: React.ReactNode;
  isEmpty?: boolean;
  emptyProps?: Partial<EmptyListProps>;
  children?: React.ReactNode;
  border?: boolean;
  wrapper?: boolean;
}

export const BasePanel = React.forwardRef<HTMLDivElement, BasePanelProps>(
  (
    {
      title,
      headerClassName,
      contentClassName,
      className,
      headerAction,
      footer,
      isEmpty,
      emptyProps,
      children,
      border = true,
      wrapper = true,
    },
    ref,
  ) => {
    const noBorder = !border && "border-none rounded-none";

    return (
      <div
        ref={ref}
        className={cn(
          "flex flex-col w-full rounded-xl overflow-hidden border border-secondary-200",
          noBorder,
          className,
        )}
      >
        <div
          className={cn(
            "flex flex-shrink-0 items-center justify-between h-12 px-5 bg-secondary-50",
            headerClassName,
          )}
        >
          <p className="font-medium text-sm">{title}</p>
          {headerAction}
        </div>

        {isEmpty ? (
          <div className={cn("flex-1 overflow-y-auto", contentClassName)}>
            <EmptyListVertical
              {...emptyProps}
              title={emptyProps?.title}
              description={emptyProps?.description}
            />
          </div>
        ) : null}

        {!isEmpty && !wrapper ? children : null}

        {!isEmpty && wrapper ? (
          <div className={cn("flex-1 overflow-y-auto", contentClassName)}>
            <div className="grid grid-cols-1">{children}</div>
          </div>
        ) : null}

        {footer}
      </div>
    );
  },
);

BasePanel.displayName = "BasePanel";
