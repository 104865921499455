import { useCallback, useEffect, useMemo, useState } from "react";
import { json2csv } from "csv42";
import { useOrganisation } from "contexts/OrganisationProvider";
import { useQuery } from "@tanstack/react-query";
import { LoaderIcon } from "lucide-react";
import { logError } from "shared/services/ErrorReporting";
import { toast } from "utils/toast";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { AccountOrganisationFileResponse } from "__generated__/api";
import { saveAs } from "file-saver";
import { OrganisationOnixUploadFileUpload } from "./components/OrganisationOnixUploadFileUpload";
import { OrganisationOnixUploadFile } from "./components/OrganisationOnixUploadFile";
import { OrganisationOnixUploadFileReportDialog } from "./components/OrganisationOnixUploadFileReportDialog";
import LoadingSpinner from "../../../../../components/loading/LoadingSpinner";

function getFileState(file: AccountOrganisationFileResponse) {
  if (file.timedOut) {
    return "error";
  }
  return file.report?.progress === 1 ? "success" : "processing";
}

interface DownloadProduct {
  isbn: string;
  title?: string;
  author?: string[];
  productGroupDescription?: string | null;
  publishedAt?: Date | null;
  failed?: boolean;
}

function downloadCSV(products: DownloadProduct[]) {
  try {
    const productsForCSV = products.map((product) => ({
      ISBN: product.isbn,
      Author: product.author?.join(", ") || "",
      Title: product.title || "",
      "Product Form": product.productGroupDescription || "",
      "Published On": product.publishedAt
        ? format(product.publishedAt, "yyyy-MM-dd")
        : "",
      Status: product.failed ? "Failed" : "Success",
    }));

    const csv = json2csv(productsForCSV);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `onix-report-${format(new Date(), "yyyy-MM-dd")}.csv`);
  } catch (error) {
    logError(`Failed to save CSV file: ${error}`);
  }
}

export const OrganisationOnixUploadPage = () => {
  const [filename, setFilename] = useState<string | null>(null);
  const [uploading, setUploading] = useState<File | null>(null);
  const [poll, setPoll] = useState(false);
  const { t } = useTranslation(["settings"]);

  const { addFile, getFiles, getReport, organisation } = useOrganisation();

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["getFiles", organisation?.id],
    queryFn: () => getFiles(),
    enabled: !!organisation?.id,
    throwOnError: (error?: Error) => {
      if (error) {
        logError(error.message || error);
      }
      if (error?.message) {
        toast.error(error?.message);
      }

      return false;
    },
    refetchInterval: poll ? 5 * 1000 : 0,
    refetchIntervalInBackground: true,
  });

  useEffect(() => {
    setPoll(
      data?.items?.some(
        (file) =>
          (file.report?.progress && file.report.progress < 1) ||
          (!file.report?.progress && file.timedOut === false),
      ) || false,
    );
  }, [data]);

  const handleFileUpload = useCallback(
    ([file]: File[]) => {
      setUploading(file);
      addFile(file)
        .catch((error) => {
          logError(error.message);
          toast.error(error.message);
        })
        .then(() => refetch())
        .then(() => setUploading(null))
        .then(() => {
          toast.success(t("settings:organisation.onix.uploading"), {
            icon: <LoaderIcon className="animate-spin duration-2000 w-5 h-5" />,
          });
        });
    },
    [addFile, refetch, t],
  );

  const { data: dataReport, isLoading: loadingReport } = useQuery({
    queryKey: ["getFiles", filename],
    queryFn: () => getReport(filename!),
    enabled: Boolean(filename),
  });

  const products = useMemo(
    () =>
      dataReport?.report?.allIsbns?.map((isbn) => ({
        isbn,
        failed: dataReport?.report?.failedIsbns?.includes(isbn),
        ...dataReport?.products?.find((product) => product.identifier === isbn),
      })) || [],
    [dataReport],
  );

  return (
    <div>
      <OrganisationOnixUploadFileUpload onFileUpload={handleFileUpload} />
      {isLoading ? (
        <div className="flex items-center justify-center w-full pt-10">
          <LoadingSpinner size="100px" />
        </div>
      ) : null}
      <ul className="mt-8 flex flex-col gap-2">
        {uploading ? (
          <OrganisationOnixUploadFile
            fileName={uploading.name}
            fileType="Onix"
            fileSizeMB={(uploading.size || 0) / 1024 / 1024}
            uploadDate={format(new Date(), "P")}
            state="processing"
          />
        ) : null}
        {data?.items?.map((file) => (
          <OrganisationOnixUploadFile
            key={file.name}
            fileName={file.name}
            fileType="Onix"
            fileSizeMB={(file.size || 0) / 1024 / 1024}
            uploadDate={file.date ? format(file.date, "P") : ""}
            failed={file.report?.failedCount || 0}
            succeeded={file.report?.succeededCount || 0}
            onActionClick={() => setFilename(file.name)}
            progress={(file.report?.progress || 0) * 100}
            state={getFileState(file)}
          />
        ))}
      </ul>
      <OrganisationOnixUploadFileReportDialog
        open={!!filename}
        loading={loadingReport}
        onExportClick={() => downloadCSV(products)}
        onClose={() => setFilename(null)}
        products={products}
        succeeded={dataReport?.report?.succeededCount}
        failed={dataReport?.report?.failedCount}
        total={dataReport?.report?.allCount}
      />
    </div>
  );
};
