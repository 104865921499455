import { Client } from "@hey-api/client-fetch";
import {
  cancelEvaluation,
  getEvaluation,
  postEvaluation,
  zCancelEvaluationResponse,
  zGetEvaluationResponse,
  zPostEvaluationResponse,
} from "reedy-data/api";
import { parseResult } from "../parseHelper";

export class EvaluationApi {
  constructor(private client: Client) {}

  async evaluate(ids: string[] | "ALL") {
    const response = await postEvaluation({
      body: { requestedProducts: ids },
      client: this.client,
    });

    const result = parseResult(zPostEvaluationResponse, response, {});

    return result?.data;
  }

  async getEvaluation(id: string) {
    const response = await getEvaluation({
      path: { identifier: id },
      client: this.client,
    });

    const result = parseResult(zGetEvaluationResponse, response, {});

    return result?.data;
  }

  async cancel(id: string) {
    const response = await cancelEvaluation({
      path: { identifier: id },
      client: this.client,
    });

    const result = parseResult(zCancelEvaluationResponse, response, {});

    return result?.data;
  }
}
