import { format, parse } from "date-fns";
import { DATE_FORMAT } from "hooks/search/constants";
import {
  DateFilterSchema,
  Filter,
  ValidNumericFilterSchema,
} from "hooks/search/types";
import { TFunction } from "i18next";

export const isValidFilter = (filter: Filter): boolean =>
  [ValidNumericFilterSchema, DateFilterSchema].some(
    (schema) => schema.safeParse(filter).success,
  );

export const getNoOfValidFilters = (filters: Record<string, Filter>): number =>
  Object.entries(filters).filter(([, filter]) => isValidFilter(filter)).length;

export const transformToInputDate = (date: Date | string) => {
  if (!date || typeof date === "string") return date;
  return format(date, DATE_FORMAT);
};

export const parseDateRange = (dateRange: { from?: string; to?: string }) => {
  return {
    from: dateRange.from
      ? parse(dateRange.from, DATE_FORMAT, new Date())
      : undefined,
    to: dateRange.to ? parse(dateRange.to, DATE_FORMAT, new Date()) : undefined,
  };
};

export const isValidFilterDate = (date: string): boolean => {
  const regex = /^\d{2}\.\d{2}\.\d{4}$/;
  return regex.test(date);
};

export const getErrorMessage = (
  dateRange: {
    from: string;
    to: string;
  },
  t: TFunction,
): string => {
  if (!dateRange.from || !dateRange.to) {
    return "";
  }
  if (!isValidFilterDate(dateRange.from) || !isValidFilterDate(dateRange.to)) {
    return t("books:filters.errors.invalidDate");
  }
  const { from, to } = parseDateRange(dateRange);
  if (from && to && from > to) {
    return t("books:filters.errors.fromAfterTo");
  }
  return "";
};
