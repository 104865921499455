const o = (n, i) => {
  var e, s;
  if (n)
    return i ? (s = n.descriptions) == null ? void 0 : s.find((t) => t.hash === i) : (e = n.descriptions) == null ? void 0 : e.find((t) => t.default);
}, r = (n) => {
  var i;
  return (i = n == null ? void 0 : n.descriptions) == null ? void 0 : i.find((e) => e.default);
}, c = (n) => {
  var i, e;
  return (e = (i = n == null ? void 0 : n.actions) == null ? void 0 : i.descriptions) == null ? void 0 : e.actions;
}, f = (n, i) => {
  var e, s, t;
  if (!(!n || !i))
    return (t = (s = (e = n.actions) == null ? void 0 : e.descriptions) == null ? void 0 : s.actions) == null ? void 0 : t[i];
}, a = (n) => {
  var i;
  return f(n, (i = r(n)) == null ? void 0 : i.hash);
};
export {
  c as getAllDescriptionActions,
  r as getDefaultDescription,
  a as getDefaultDescriptionActions,
  o as getDescription,
  f as getDescriptionActions
};
