import { useTranslation } from "react-i18next";
import OnixFileIcon from "assets/svg/onix-file-icon.svg?react";
import { Button } from "components/button";
import { Badge } from "components/ui/badge";
import { Loader } from "components/loading/Loader";
import { Progress } from "components/ui/progress";
import React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../../components/ui/tooltip";

import { Error } from "../../../../../../components/icon/Error";

export const OrganisationOnixUploadFile = ({
  fileName,
  fileType,
  fileSizeMB,
  uploadDate,
  state,
  onActionClick,
  failed,
  succeeded,
  progress = 0,
}: {
  fileName: string;
  fileType: string;
  fileSizeMB: number;
  uploadDate: string;
  failed?: number;
  succeeded?: number;
  state: "error" | "success" | "processing";
  onActionClick?: () => void;
  progress?: number;
}) => {
  const { t } = useTranslation(["settings"]);

  return (
    <li className="flex border-secondary-200 border rounded-xl p-3 justify-between items-center">
      <div className="flex gap-3">
        <div className="border-secondary-200 border rounded-lg size-11 flex items-center justify-center">
          <OnixFileIcon />
        </div>
        <div className="flex flex-col justify-center">
          <p className="text-sm font-medium">{fileName}</p>
          <p className="text-xs text-secondary-400 mt-1">
            {fileType} &middot; {fileSizeMB.toFixed(2)} MB &middot; {uploadDate}
          </p>
        </div>
      </div>
      <div className="flex gap-3 items-center">
        {failed ? (
          <div className="text-xs flex whitespace-nowrap items-center">
            {t("settings:organisation.onix.misc.books", { count: failed })}
            <Badge variant="error" className="ml-2">
              {t("settings:organisation.onix.misc.badge.failed")}
            </Badge>
          </div>
        ) : null}
        {succeeded ? (
          <div className="text-xs flex whitespace-nowrap items-center">
            {t("settings:organisation.onix.misc.books", {
              count: succeeded,
            })}
            <Badge variant="success" className="ml-2">
              {t("settings:organisation.onix.misc.badge.success")}
            </Badge>
          </div>
        ) : null}
        {state === "error" ? (
          <Tooltip>
            <TooltipTrigger>
              <Error pulse={false} iconSize="small" />
            </TooltipTrigger>
            <TooltipContent>
              <div className="flex flex-col items-start">
                {t("settings:organisation.onix.misc.error")}
              </div>
            </TooltipContent>
          </Tooltip>
        ) : null}
        {state === "success" ? (
          <Button
            variant="tertiary"
            size="small"
            icon="move-up-right"
            iconPosition="right"
            onClick={onActionClick}
          >
            {t("settings:organisation.onix.file.button.report")}
          </Button>
        ) : null}
        {state === "processing" && progress === 0 ? (
          <Loader className="size-6 mr-2 border-2" />
        ) : null}
        {state === "processing" && progress > 0 ? (
          <Progress value={progress} className="w-48 h-2 bg-secondary-200" />
        ) : null}
      </div>
    </li>
  );
};
