import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useProject } from "hooks/useProject";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFixedFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Button } from "components/button";
import { Icon } from "components/icon/Icon";
import { useDialog } from "hooks/useDialog";
import { transformMultipleIsbn } from "shared/services/IsbnService";
import { toast } from "utils/toast";
import { ProjectForm } from "./ProjectForm";
import { getProductsByIsbns } from "../../../integrations/firebase/collections";

export const ProjectEditISBNDialog: React.FC = () => {
  const { t } = useTranslation(["general", "projectDetails"]);
  const { idProject = "0" } = useParams();
  const [loading, setLoading] = useState(false);
  const { isOpen, onClose, onOpen } = useDialog();
  const {
    project,
    isLoadingRead,
    errorRead,
    errorUpdate,
    products,
    updateProjectIds,
  } = useProject(idProject);
  const [errorSave, setErrorSave] = useState<Error>();

  const productIsbns = useMemo(
    () =>
      products
        ? transformMultipleIsbn(
            products.map((product) => product.identifier || ""),
          )
        : [],
    [products],
  );

  const [selectedIsbns, setSelectedIsbns] = useState<string[]>(productIsbns);

  const filterIsbnsOnDialogueClose = useCallback(() => {
    const duplicatesRemoved = Array.from(new Set(selectedIsbns));
    setSelectedIsbns(duplicatesRemoved);
    return duplicatesRemoved;
  }, [selectedIsbns]);

  useEffect(() => {
    setSelectedIsbns(productIsbns);
  }, [productIsbns]);

  const handleSave = useCallback(() => {
    if (!idProject || !project?.organisation.id) {
      return;
    }
    setLoading(true);
    const isbns = filterIsbnsOnDialogueClose();
    getProductsByIsbns(project.organisation.id, isbns)
      .then((productsFromIsbns) => {
        const foundIsbns = productsFromIsbns.map((product) => product.isbn);
        const foundIds = productsFromIsbns.map((product) => product.id);
        updateProjectIds(idProject, foundIds);
        setSelectedIsbns(foundIsbns);
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        setLoading(false);
        setErrorSave(error);
      });
  }, [
    project,
    idProject,
    updateProjectIds,
    onClose,
    filterIsbnsOnDialogueClose,
  ]);

  const getErrorText = useCallback(() => {
    if (errorRead) {
      return `${t("projectDetails:toast.errorReadProject")}: ${
        errorRead.message
      }`;
    }
    if (errorUpdate) {
      return `${t("projectDetails:toast.errorUpdateProject")}: ${
        errorUpdate.message
      }`;
    }
    if (errorSave) {
      return `${t("projectDetails:toast.errorUpdateProject")}: ${
        errorSave.message
      }`;
    }
  }, [errorSave, errorRead, errorUpdate, t]);

  useEffect(() => {
    if (errorRead || errorUpdate || errorSave) {
      toast.error(getErrorText() || "");
    }
  }, [errorSave, errorRead, errorUpdate, getErrorText, onClose]);

  const onDialogueOpenChanged = (isDialogueOpen: boolean) => {
    if (!isDialogueOpen) {
      onClose();
      filterIsbnsOnDialogueClose();
    }
  };

  return (
    <Dialog
      onOpenChange={onDialogueOpenChanged}
      open={isOpen}
      modal
      defaultOpen={false}
    >
      <DialogTrigger asChild>
        <Button
          variant="tertiary"
          icon="square-pen"
          spanClassName="text-sm text-secondary-600"
          onClick={onOpen}
          label={t("projectDetails:buttons.editBooks")}
        />
      </DialogTrigger>
      <DialogContent className="max-w-[90%]">
        <DialogHeader>
          <DialogTitle className="text-left w-full mb-0">
            {t("projectDetails:editISBN.title")}
          </DialogTitle>
          <DialogDescription />
        </DialogHeader>
        <ProjectForm
          selectedIsbns={selectedIsbns}
          setSelectedIsbns={setSelectedIsbns}
          loading={isLoadingRead || loading}
        />
        <DialogFixedFooter>
          <div className="flex flex-row justify-between w-full items-center pl-2">
            <span className="text-secondary-500 text-sm flex-row flex items-center gap-1">
              <Icon name="info" size="medium" />
              <p>{t("projectDetails:editISBN.info")}</p>
            </span>

            <Button
              size="small"
              label={t("general:buttons.save")}
              onClick={handleSave}
            />
          </div>
        </DialogFixedFooter>
      </DialogContent>
    </Dialog>
  );
};
