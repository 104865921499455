import React from "react";
import { Button } from "components/button";
import { useTranslation } from "react-i18next";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectPlaceholder,
  SelectTrigger,
} from "components/ui/select";
import { UserRole } from "__generated__/models";
import { useOrganisation } from "contexts/OrganisationProvider";
import startCase from "lodash/startCase";
import { toast } from "utils/toast";

interface Member {
  email: string;
  role: UserRole;
}

interface FormValues {
  users: Member[];
}

interface AddOrganisationMemberFormProps {
  onClose(): void;
}

export const AddOrganisationMemberForm = ({
  onClose,
}: AddOrganisationMemberFormProps) => {
  const { t } = useTranslation(["settings"]);

  const { addUsers } = useOrganisation();

  const { formState, handleSubmit, register, control } = useForm<FormValues>({
    defaultValues: {
      users: [
        {
          email: "",
          role: UserRole.MEMBER,
        },
      ],
    },
  });

  async function onCreate(values: FormValues) {
    if (formState.isSubmitting) {
      return;
    }

    try {
      await addUsers(values.users);

      toast.success(t("settings:organisation.members.add.success"));

      onClose();
    } catch (error) {
      toast.error((error as Error).message);
    }
  }

  const members = useFieldArray({
    control,
    name: "users",
  });

  return (
    <>
      <form
        id="add-organisation-members"
        onSubmit={handleSubmit(onCreate)}
        className="grid gap-3 grid-cols-[1fr,150px,auto]"
      >
        <Label className="flex flex-col justify-center space-y-3">
          <div>{t("settings:organisation.members.add.modal.email.label")}</div>
        </Label>
        <Label className="flex flex-col justify-center space-y-3">
          <div>Role</div>
        </Label>

        <div />

        {members.fields.map((field, index) => (
          <div
            className="grid grid-cols-subgrid gap-3 col-span-full"
            key={field.id}
          >
            <Input
              className="placeholder:text-secondary-400 rounded-lg placeholder:text-sm placeholder:font-normal text-sm h-full"
              placeholder={t(
                "settings:organisation.members.add.modal.email.placeholder",
              )}
              type="email"
              {...register(`users.${index}.email`, {
                required: true,
                setValueAs: (v) => v.trim(),
              })}
            />

            <Controller
              name={`users.${index}.role`}
              control={control}
              rules={{ required: true }}
              render={({ field: roleField }) => (
                <Select
                  onValueChange={roleField.onChange}
                  value={roleField.value}
                >
                  <SelectTrigger
                    aria-label="category"
                    className="h-full border-secondary-300 bg-white"
                  >
                    <SelectPlaceholder
                      placeholder={t(
                        "settings:organisation.members.add.modal.role.placeholder",
                      )}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {Object.entries(UserRole).map(([key, value]) => (
                        <SelectItem
                          aria-label={key}
                          key={key}
                          value={value}
                          className="text-sm"
                        >
                          {startCase(value.toLowerCase())}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              )}
            />

            <Button
              icon="trash"
              variant="tertiary"
              size="small"
              className="aspect-square p-3"
              disabled={members.fields.length === 1}
              onClick={() => members.remove(index)}
            />
          </div>
        ))}
      </form>

      <Button
        type="button"
        size="small"
        icon="plus"
        variant="tertiary"
        className="aspect-square p-3"
        onClick={() =>
          members.append({
            email: "",
            role: UserRole.MEMBER,
          })
        }
      />
    </>
  );
};
