import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "components/ui/tooltip";
import {
  getActionsByType,
  getKeywordsByType,
  IProduct,
} from "integrations/firebase/collections";
import { actionInProgress } from "utils/status";
import { Modal } from "components/modal/Modal";
import { getAllDescriptionActions } from "reedy-data";
import { DescriptionType } from "api/client/products";
import { GENERATE_METADATA_BOOKS_LIMIT } from "../../../../shared/constants";
import { BulkGenerateOption } from "./BulkGenerateOption";
import { AutofillSwitch } from "./AutofillSwitch";
import { DescriptionTypeMultiSelect } from "./DescriptionTypeMultiSelect";

export function BulkGenerateButton({
  products,
  generateKeywords,
  generateDescriptions,
}: {
  products: IProduct[];
  generateKeywords: (products: IProduct[], autofillFinalList?: boolean) => void;
  generateDescriptions: (
    products: IProduct[],
    descriptionTypes: DescriptionType[],
  ) => void;
}) {
  const { t } = useTranslation(["projectDetails", "general"]);
  const [autofillFinalList, setAutofillFinalList] = useState(false);
  const [selectedDescriptionTypes, setSelectedDescriptionTypes] = useState<
    DescriptionType[]
  >([]);

  const [showGenerateDescriptionModal, setShowGenerateDescriptionModal] =
    useState(false);
  const [showOverwriteDescriptionModal, setShowOverwriteDescriptionModal] =
    useState(false);
  const [showKeywordsGenerationWarning, setShowKeywordsGenerationWarning] =
    useState(false);
  const [showMissingDescriptionsWarning, setShowMissingDescriptionsWarning] =
    useState(false);
  const [booksWithMissingDescriptions, setBooksWithMissingDescriptions] =
    useState<IProduct[]>([]);

  const hasGeneratedKeywords = products.some((p) =>
    Boolean(getKeywordsByType(p).generated.length),
  );

  const existingDescriptionTypes = useMemo(() => {
    const types: Array<DescriptionType> = [];
    products.forEach((product) => {
      const descriptions = product.descriptions || [];
      descriptions.forEach((description) => {
        if (description.textType && description.audiences?.length) {
          description.audiences.forEach((audience) => {
            if (description.textType) {
              types.push({
                textType: description.textType,
                audience,
              });
            }
          });
        }
      });
    });
    const uniqueTypes = types.reduce((unique, desc) => {
      const key = `${desc.textType}_${desc.audience}`;
      return unique.set(key, desc);
    }, new Map<string, DescriptionType>());
    const uniqueTypesArray = Array.from(uniqueTypes.values());
    return uniqueTypesArray;
  }, [products]);

  const checkForOverwritingDescriptions = useCallback(
    (types: DescriptionType[]) => {
      return types.some((type) =>
        products.some((product) => {
          const descriptions = product.descriptions || [];
          return descriptions.some(
            (desc) =>
              desc.textType === type.textType &&
              desc.audiences?.includes(type.audience) &&
              Boolean(desc.textGenerated?.length),
          );
        }),
      );
    },
    [products],
  );

  const handleGenerateKeywordsClick = useCallback(
    async (skipWarning = false) => {
      if (!skipWarning) {
        setShowKeywordsGenerationWarning(true);
        return;
      }

      setShowKeywordsGenerationWarning(false);

      await generateKeywords(products, autofillFinalList);
    },
    [generateKeywords, products, autofillFinalList],
  );

  const handleGenerateDescriptionsClick = useCallback(async () => {
    if (selectedDescriptionTypes.length === 0) return;

    const willOverwrite = checkForOverwritingDescriptions(
      selectedDescriptionTypes,
    );
    const missingDescriptionsBooks = products.filter((product) => {
      return !product.descriptions?.some((desc) =>
        selectedDescriptionTypes.some(
          (type) =>
            desc.textType === type.textType &&
            desc.audiences?.includes(type.audience),
        ),
      );
    });

    if (missingDescriptionsBooks.length > 0) {
      setBooksWithMissingDescriptions(missingDescriptionsBooks);
      setShowGenerateDescriptionModal(false);
      setShowMissingDescriptionsWarning(true);
      return;
    }

    if (willOverwrite) {
      setShowGenerateDescriptionModal(false);
      setShowOverwriteDescriptionModal(true);
      return;
    }

    setShowGenerateDescriptionModal(false);
    await generateDescriptions(products, selectedDescriptionTypes);
  }, [
    generateDescriptions,
    products,
    selectedDescriptionTypes,
    checkForOverwritingDescriptions,
  ]);

  const handleMissingDescriptionsConfirm = useCallback(async () => {
    setShowMissingDescriptionsWarning(false);
    setBooksWithMissingDescriptions([]);
    const willOverwrite = checkForOverwritingDescriptions(
      selectedDescriptionTypes,
    );

    if (willOverwrite) {
      setShowOverwriteDescriptionModal(true);
      return;
    }

    generateDescriptions(products, selectedDescriptionTypes);
  }, [
    generateDescriptions,
    products,
    selectedDescriptionTypes,
    checkForOverwritingDescriptions,
  ]);

  const handleOverwriteConfirm = useCallback(async () => {
    setShowOverwriteDescriptionModal(false);
    generateDescriptions(products, selectedDescriptionTypes);
  }, [generateDescriptions, products, selectedDescriptionTypes]);

  const { generateAllEnabled, isGenerating } = useMemo(() => {
    if (!products?.length) {
      return { generateAllEnabled: false, isGenerating: false };
    }

    const hasRequested = products.some((p) => {
      const { keywords } = getActionsByType(p);

      const descriptionActions = getAllDescriptionActions(p);
      const descriptionActionsValues = descriptionActions
        ? Object.values(descriptionActions)
        : [];
      const descriptionActionsValuesGenerate = descriptionActionsValues
        .map((a) => a?.generate)
        .filter((a) => a !== undefined);

      return actionInProgress(
        ...descriptionActionsValuesGenerate,
        keywords?.generate,
      );
    });

    const hasErrors = products.some((p) => p.nonExistent);

    if (hasErrors) {
      return { generateAllEnabled: false, isGenerating: hasRequested };
    }

    return { generateAllEnabled: !hasRequested, isGenerating: hasRequested };
  }, [products]);

  const disabled =
    isGenerating ||
    !generateAllEnabled ||
    products.length > GENERATE_METADATA_BOOKS_LIMIT;

  const getTooltipContent = () => {
    if (products.length === 0) {
      return t("projectDetails:buttons.generate.disabledNoBooks", {
        count: GENERATE_METADATA_BOOKS_LIMIT,
      });
    }

    if (products.length > GENERATE_METADATA_BOOKS_LIMIT) {
      return t("projectDetails:buttons.generate.disabledLimitBooks", {
        count: GENERATE_METADATA_BOOKS_LIMIT,
      });
    }

    if (!disabled) {
      return t("projectDetails:buttons.generateAll.tooltips.enabled");
    }

    if (isGenerating) {
      return t("projectDetails:buttons.generateAll.tooltips.generating");
    }

    return null;
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger
          disabled={disabled}
          className="disabled:text-secondary-400 text-secondary-700 group"
        >
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="rounded-lg border flex-1 flex group-aria-expanded:border-secondary-600 cursor-pointer transition-colors">
                <div className="whitespace-nowrap font-normal flex items-center px-5 text-md">
                  {isGenerating
                    ? t("projectDetails:buttons.generateAll.generating")
                    : t("projectDetails:buttons.generateAll.metadata")}
                </div>

                <div className="transition-colors w-11 h-full border-l aspect-square flex items-center justify-center group-aria-expanded:border-l-secondary-600">
                  <ChevronDown className="w-5 h-5 transition-transform group-aria-expanded:rotate-180" />
                </div>
              </div>
            </TooltipTrigger>

            {getTooltipContent() && (
              <TooltipContent
                data-testid="generate-all-tooltip"
                side="bottom"
                className="font-light text-center shadow-tooltip text-neutral-600 whitespace-pre px-5 z-10 border-none leading-relaxed"
                sideOffset={6}
              >
                <TooltipArrow
                  className="fill-white drop-shadow-sm"
                  width={12}
                  height={6}
                />
                {getTooltipContent()}
              </TooltipContent>
            )}
          </Tooltip>
        </DropdownMenuTrigger>

        <DropdownMenuContent
          side="bottom"
          align="center"
          className="popover-content-width-full"
        >
          <BulkGenerateOption
            onClick={() => handleGenerateKeywordsClick(false)}
            label={t("projectDetails:buttons.generateAll.items.keywords")}
          />
          <BulkGenerateOption
            onClick={() => setShowGenerateDescriptionModal(true)}
            label={t("projectDetails:buttons.generateAll.items.descriptions")}
          />
        </DropdownMenuContent>
      </DropdownMenu>

      <Modal
        id="generate-description"
        variant="primary"
        title={t("projectDetails:modals.generateDescriptions.title")}
        description={t(
          "projectDetails:modals.generateDescriptions.description",
        )}
        confirmLabel={t("general:buttons.generate")}
        confirmIconName="loader"
        cancelLabel={t("general:buttons.cancel")}
        open={showGenerateDescriptionModal}
        onOpenChange={setShowGenerateDescriptionModal}
        onConfirm={handleGenerateDescriptionsClick}
        disabledConfirm={selectedDescriptionTypes.length === 0}
      >
        <DescriptionTypeMultiSelect
          selectedDescriptionTypes={selectedDescriptionTypes}
          setSelectedDescriptionTypes={setSelectedDescriptionTypes}
          existingDescriptionTypes={existingDescriptionTypes}
        />
      </Modal>

      <Modal
        id="overwrite-description"
        variant="warning"
        title={t("projectDetails:modals.overwriteDescriptions.title")}
        description={t(
          "projectDetails:modals.overwriteDescriptions.description",
        )}
        confirmLabel={t(
          "projectDetails:modals.overwriteDescriptions.actions.confirm",
        )}
        confirmIconName="loader"
        cancelLabel={t("general:buttons.cancel")}
        open={showOverwriteDescriptionModal}
        onOpenChange={setShowOverwriteDescriptionModal}
        onConfirm={handleOverwriteConfirm}
      />

      <Modal
        id="overwrite-keywords"
        variant={hasGeneratedKeywords ? "warning" : "primary"}
        title={
          hasGeneratedKeywords
            ? t("projectDetails:modals.overwriteKeywords.title")
            : t("projectDetails:modals.generateKeywords.title")
        }
        description={
          hasGeneratedKeywords
            ? t("projectDetails:modals.overwriteKeywords.description")
            : t("projectDetails:modals.generateKeywords.description")
        }
        confirmLabel={
          hasGeneratedKeywords
            ? t("projectDetails:modals.overwriteKeywords.actions.confirm")
            : t("general:buttons.generate")
        }
        confirmIconName="loader"
        cancelLabel={t("general:buttons.cancel")}
        open={showKeywordsGenerationWarning}
        onOpenChange={() => setShowKeywordsGenerationWarning(false)}
        onConfirm={() => handleGenerateKeywordsClick(true)}
      >
        <AutofillSwitch
          checked={autofillFinalList}
          onCheckedChange={setAutofillFinalList}
        />
      </Modal>

      <Modal
        id="missing-descriptions"
        variant="warning"
        title={t("projectDetails:modals.missingDescriptions.title")}
        description={t("projectDetails:modals.missingDescriptions.description")}
        confirmLabel={t("general:buttons.continue")}
        confirmIconName="loader"
        cancelLabel={t("general:buttons.cancel")}
        open={showMissingDescriptionsWarning}
        onOpenChange={(open) => {
          setShowMissingDescriptionsWarning(open);
          if (!open) {
            setBooksWithMissingDescriptions([]);
          }
        }}
        onConfirm={handleMissingDescriptionsConfirm}
      >
        <div className="mt-4 text-sm text-secondary-600">
          <p className="font-medium mb-2">
            {t("projectDetails:modals.missingDescriptions.affectedBooks", {
              count: booksWithMissingDescriptions.length,
            })}
          </p>
          <div className="max-h-40 overflow-y-auto border rounded-md">
            <div className="flex flex-wrap gap-1 p-2">
              {booksWithMissingDescriptions.map((book) => (
                <span
                  key={book.isbn}
                  className="bg-secondary-100 text-secondary-700 px-2 py-0.5 rounded text-xs font-medium"
                >
                  {book.isbn}
                </span>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
