import { Table } from "@tanstack/react-table";
import { Button } from "components/button";
import { Info } from "components/icon/Info";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFixedFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { useTrends } from "contexts/TrendsContext";
import { useDialog } from "hooks/useDialog";
import { IProduct } from "integrations/firebase/collections";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "shared/constants";

interface ISaveAsProjectDialogProps {
  table: Table<IProduct>;
  handleSaveProject: (title: string) => void;
  hasSelectionEnabled?: boolean;
  projectSaved?: string;
}

export const SaveAsProjectDialog = ({
  table,
  handleSaveProject,
  hasSelectionEnabled = true,
  projectSaved,
}: ISaveAsProjectDialogProps) => {
  const { isOpen, onClose, onOpen, onOpenChange } = useDialog();
  const { t } = useTranslation(["selectedSearchTerms"]);
  const inputRef = useRef<HTMLInputElement>(null);
  const { currentTrendWithProducts } = useTrends();
  const navigate = useNavigate();

  const onSaveProject = async () => {
    handleSaveProject(inputRef.current?.value || "");
    onClose();
  };

  const onGoToProject = () => {
    navigate(RoutePath.ProjectDetails.replace(":idProject", projectSaved!));
    onClose();
  };

  return (
    <Dialog onOpenChange={onOpenChange} open={isOpen} modal defaultOpen={false}>
      <DialogTrigger asChild>
        {projectSaved ? (
          <Button
            label={t("selectedSearchTerms:selectedBooks.goToProject")}
            onClick={onGoToProject}
            icon="folder"
          />
        ) : (
          <Button
            label={t("selectedSearchTerms:selectedBooks.saveAsProject")}
            onClick={onOpen}
            icon="folder-plus"
            disabled={
              hasSelectionEnabled &&
              !table.getSelectedRowModel().flatRows.length
            }
          />
        )}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <Info />

          <DialogTitle className="mt-0">
            {t("selectedSearchTerms:saveAsProjectModal.title")}
          </DialogTitle>
          <DialogDescription>
            {t("selectedSearchTerms:saveAsProjectModal.description")}
          </DialogDescription>
        </DialogHeader>

        <div className="flex flex-col gap-2 py-3">
          <p className="text-secondary-600 text-md font-medium">
            {t("selectedSearchTerms:saveAsProjectModal.inputLabel")}
          </p>
          <Input
            ref={inputRef}
            defaultValue={currentTrendWithProducts?.term || ""}
            placeholder={t(
              "selectedSearchTerms:saveAsProjectModal.inputPlaceholder",
            )}
          />
        </div>
        <DialogFixedFooter className="flex flex-row justify-end">
          <Button
            variant="tertiary"
            label={t("selectedSearchTerms:saveAsProjectModal.buttons.cancel")}
            onClick={onClose}
          />
          <Button
            icon="save"
            label={t("selectedSearchTerms:saveAsProjectModal.buttons.save")}
            onClick={onSaveProject}
          />
        </DialogFixedFooter>
      </DialogContent>
    </Dialog>
  );
};
