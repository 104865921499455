import { TrendsContextProvider } from "contexts/TrendsContext";
import { ProjectsContextProvider } from "pages/Projects/contexts/ProjectsContext";
import { Layout } from "components/layout";
import { Navigate, Outlet } from "react-router-dom";
import { ProfileModal } from "components/modal/ProfileModal";
import { useAuth } from "contexts/AuthContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

export function AuthLayout() {
  const { authUser } = useAuth();

  if (!authUser) {
    return <Navigate to="/login" />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      {import.meta.env.DEV && <ReactQueryDevtools client={queryClient} />}
      <TrendsContextProvider>
        <ProjectsContextProvider>
          <Layout>
            <Outlet />
            <ProfileModal />
          </Layout>
        </ProjectsContextProvider>
      </TrendsContextProvider>
    </QueryClientProvider>
  );
}
