import { Table } from "@tanstack/react-table";
import { IProduct } from "integrations/firebase/collections";
import { useTranslation } from "react-i18next";
import { useDeepCompareMemo } from "use-deep-compare";
import { useCallback } from "react";
import { SearchToolbar } from "../components/SearchToolbar";
import { SaveAsProjectButton } from "./SaveAsProjectButton";

export const PreHeader = ({ table }: { table: Table<IProduct> }) => {
  const { t } = useTranslation(["books"]);

  const selectedTableIds = table.getSelectedIds();
  const selectedBooksCount = table.getSelectedCount();
  const isSelectedAll = table.getSelectedAll();
  const selectedIds = useDeepCompareMemo(
    () => selectedTableIds,
    [selectedTableIds],
  );
  const allBooksCount = table.getRowCount();

  const clearSelection = useCallback(
    () => table.toggleAllRowsSelected(false),
    [table],
  );

  return (
    <div className="grid grid-cols-[1fr,auto] gap-4">
      <SearchToolbar onSearch={clearSelection} />

      <div className="grid grid-cols-[auto,auto] items-center space-x-4">
        {Boolean(selectedBooksCount) && (
          <div className="text-secondary-500 text-nowrap text-sm">
            {isSelectedAll
              ? t("books:allSelected", { count: selectedBooksCount })
              : t("books:selected", { count: selectedBooksCount })}
          </div>
        )}
        <SaveAsProjectButton
          isSelectedAll={isSelectedAll}
          selectedBooksCount={selectedBooksCount}
          selectedIds={selectedIds}
          allBooksCount={allBooksCount}
        />
      </div>
    </div>
  );
};
