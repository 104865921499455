import * as React from "react";
import { PropsWithChildren } from "react";
import {
  Tooltip as TooltipPrimitive,
  TooltipContent,
  TooltipTrigger,
} from "components/ui/tooltip";

type TooltipContentProps = Omit<
  React.ComponentPropsWithoutRef<typeof TooltipContent>,
  "content"
>;

interface TooltipProps extends TooltipContentProps {
  content?: string | null;
  open?: boolean;

  onOpenChange?(open: boolean): void;
}

export const Tooltip = ({
  children,
  open,
  onOpenChange,
  content,
  ...props
}: PropsWithChildren<TooltipProps>) => {
  return (
    <TooltipPrimitive open={open} onOpenChange={onOpenChange}>
      <TooltipTrigger asChild>{children}</TooltipTrigger>

      {content && <TooltipContent {...props}>{content}</TooltipContent>}
    </TooltipPrimitive>
  );
};
