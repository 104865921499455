import React, { useMemo } from "react";
import { AlertOctagon } from "lucide-react";
import { AuthError, AuthErrorCodes } from "firebase/auth";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "components/button";
import { RoutePath } from "shared/constants";

export interface LoginLinkErrorProps {
  error: AuthError;
}

export const AccountVerificationError = "auth/error-code:-47";

export function LoginLinkError({ error }: LoginLinkErrorProps) {
  const { t } = useTranslation(["login", "general"]);

  const { title, message, actions } = useMemo(() => {
    if (error.code === AuthErrorCodes.INVALID_OOB_CODE) {
      return {
        title: t("login:link.redirect.error.link-expired.title"),
        message: t("login:link.redirect.error.link-expired.message"),
        actions: {
          returnToLogin: true,
          contactSupport: true,
        },
      };
    }

    if (error.code === AccountVerificationError) {
      return {
        title: t("login:link.redirect.error.unregistered-user.title"),
        message: t("login:link.redirect.error.unregistered-user.message"),
        actions: {
          returnToLogin: true,
        },
      };
    }

    return {
      title: t("login:link.redirect.error.unknown-error.title"),
      message: t("login:link.redirect.error.unknown-error.message"),
      actions: {
        returnToLogin: true,
        contactSupport: true,
      },
    };
  }, [t, error]);

  return (
    <div className="flex flex-col space-y-10 h-full items-center justify-center text-center max-w-xl mx-auto container">
      <div className="rounded-full bg-icon-error-ring p-3">
        <div className="rounded-full bg-icon-error-background p-2">
          <AlertOctagon className="text-system-error h-8 w-8" />
        </div>
      </div>

      <div className="space-y-2">
        <h1 className="font-semibold text-2xl">{title}</h1>
        <p className="text-secondary-400 font-light whitespace-pre">
          {message}
        </p>
      </div>

      <div className="grid grid-flow-col gap-3">
        {actions.contactSupport && (
          <Link
            to={RoutePath.ContactUs}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="support-action"
          >
            <Button
              label={t("login:link.redirect.error.actions.support")}
              variant="tertiary"
              size="small"
              className="!text-sm"
            />
          </Link>
        )}

        {actions.returnToLogin && (
          <Link to="/login">
            <Button
              label={t("login:link.redirect.error.actions.login")}
              variant="primary"
              size="small"
              className="!text-sm font-normal"
            />
          </Link>
        )}
      </div>
    </div>
  );
}
