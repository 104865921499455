import { useCallback, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

import { IProduct, watchProducts } from "integrations/firebase/collections";
import { logError } from "shared/services/ErrorReporting";
import { FirestoreError } from "firebase/firestore";
import { useAuth } from "contexts/AuthContext";
import { useOrganisation } from "./useOrganisation";

export const useProducts = (ids: undefined | string[]) => {
  const [products, setProducts] = useState<IProduct[]>();

  const [isLoadingRead, setIsLoadingRead] = useState(true);

  const [errorRead, setErrorRead] = useState<FirestoreError>();

  const organisation = useOrganisation();
  const { userData } = useAuth();

  const subscribeProducts = useCallback(
    (idsArray: string[]) => {
      setProducts([]);
      setErrorRead(undefined);
      if (idsArray.length === 0) {
        setIsLoadingRead(false);
        return;
      }
      setIsLoadingRead(true);

      if (!organisation) {
        throw new Error(`No organisation found for user: ${userData?.email}`);
      }

      const handleNext = (newProducts: IProduct[]) => {
        const nonExistingIds = idsArray
          .filter((id) => !newProducts?.find((op) => op.id === id))
          .map((i) => {
            return {
              id: i,
              title: "",
              nonExistent: true,
            } as IProduct;
          });

        setProducts([...newProducts, ...nonExistingIds]);
        setIsLoadingRead(false);
      };

      const handleError = (err: FirestoreError) => {
        setProducts(
          (currentProducts: undefined | IProduct[]) => currentProducts ?? [],
        );
        logError(err);
        setErrorRead(err as FirestoreError);
        setIsLoadingRead(false);
      };

      const unsubscribe = watchProducts(
        idsArray,
        organisation.id,
        handleNext,
        handleError,
      );

      return () => unsubscribe;
    },
    [organisation, userData?.email],
  );

  useDeepCompareEffect(() => {
    if (!ids || ids.length === 0) {
      setIsLoadingRead(false);
      return;
    }

    const unsubscribe = subscribeProducts(ids);
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [subscribeProducts, ids]);

  return {
    isLoadingRead,
    errorRead,
    products,
  };
};
