import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { SearchQuery, SearchType } from "hooks/search/types";
import { Control, useController, useFormContext } from "react-hook-form";
import { defaultFilterValues } from "../filters/constants";

interface SearchTypeDropdownProps {
  control: Control<SearchQuery>;
}

export const SearchTypeDropdown = ({ control }: SearchTypeDropdownProps) => {
  const { t } = useTranslation(["books"]);

  const form = useFormContext();
  const { field } = useController({ name: "type", control });

  const dropdownDescriptions = useMemo(
    () => ({
      [SearchType.QUICK]: t("books:search.backlistTypes.quick"),
      [SearchType.ISBN]: t("books:search.backlistTypes.isbn"),
      [SearchType.SEMANTIC]: t("books:search.backlistTypes.semantic"),
    }),
    [t],
  );

  const onValueChange = useCallback(
    (searchType: SearchType) => {
      if (searchType === SearchType.ISBN || field.value === SearchType.ISBN) {
        // Reset query and filter when switching to/from ISBN search type
        // as ISBN search requires exact matches and doesn't support filtering
        form.reset({
          ...form.getValues(),
          type: searchType,
          query: "",
          pageSize: null,
          filters: defaultFilterValues,
        });
      } else {
        form.reset({
          ...form.getValues(),
          type: searchType,
          pageSize: null,
        });
      }
    },
    [field, form],
  );

  const options = useMemo(() => {
    return Object.entries(SearchType).map(([key, type]) => (
      <SelectItem
        key={key}
        value={type}
        data-testid={`search-dropdown-item-${key}`}
        analytics-id="button_backlist_input_type"
      >
        <p>{dropdownDescriptions[type]}</p>
      </SelectItem>
    ));
  }, [dropdownDescriptions]);

  return (
    <Select value={field.value} onValueChange={onValueChange}>
      <SelectTrigger
        className="h-full border-secondary-300 rounded-lg rounded-e-none bg-secondary-50 min-w-40"
        data-testid="search-dropdown"
      >
        <SelectValue placeholder="Search type" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>{options}</SelectGroup>
      </SelectContent>
    </Select>
  );
};
