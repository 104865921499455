import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./shared/i18n";
import { initialiseLaunchDarkly } from "./integrations/launch-darkly";
import { TooltipProvider } from "./components/ui/tooltip";

const root = ReactDOM.createRoot(document.getElementById("root")!);

const load = async () => {
  const LaunchDarklyProvider = await initialiseLaunchDarkly();

  root.render(
    <React.StrictMode>
      <LaunchDarklyProvider>
        <TooltipProvider delayDuration={100}>
          <App />
        </TooltipProvider>
      </LaunchDarklyProvider>
    </React.StrictMode>,
  );
};

load().then();
