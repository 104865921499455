import { IsbnMultipleSelectChip } from "components/select/IsbnMultipleSelectChip";
import { useCallback, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface MultiTextInputProps {
  name: string;
}

export const MultiTextInput = ({ name }: MultiTextInputProps) => {
  const { watch, setValue } = useFormContext();
  const value = watch(name) as string | undefined;
  const values = useMemo(
    () => value?.split(",").filter((v) => v) ?? [],
    [value],
  );

  const onSelectChange = useCallback(
    (v: string[]) => {
      setValue(name, v.filter(Boolean).join(","));
    },
    [setValue, name],
  );

  return (
    <div className="h-full flex-1 flex items-center relative border-secondary-300 border border-l-0 rounded-r-lg shadow-sm bg-white text-secondary-600">
      <Controller
        name={name}
        render={() => (
          <IsbnMultipleSelectChip
            values={values}
            onValueChange={onSelectChange}
            className="grid grid-cols-[repeat(auto-fill,_175px)] auto-rows-[35px] gap-y-1 gap-x-2 py-1 px-1"
            chipClassName="text-sm"
          />
        )}
      />
    </div>
  );
};
