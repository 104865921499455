import { Button } from "components/button";
import { SkeletonKeyword } from "components/skeletons/SkeletonKeyword";
import { Icon, IconProps } from "components/icon/Icon";
import { isValidElement, useMemo } from "react";
import { cn } from "utils/cn";
import { EmptyListProps } from "./EmptyListTypes";

export const EmptyListVertical: React.FC<EmptyListProps> = ({
  onClick,
  title,
  description,
  label,
  icon,
  errorMessage,
  emptyComponent,
  titleStyle,
  disabled,
  buttonProps,
}) => {
  const showButton = label || icon;
  const iconElement = useMemo(() => {
    if (typeof icon === "string") {
      return <Icon name={icon as IconProps["name"]} />;
    }
    if (isValidElement(icon)) {
      return icon;
    }
    return null;
  }, [icon]);

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex flex-col items-center">
        {emptyComponent || (
          <div className="flex justify-center flex-col">
            <SkeletonKeyword
              animated={false}
              className="scale-75 relative animate-none opacity-60"
            />
            <SkeletonKeyword
              animated={false}
              primary
              className="scale-75 relative animate-none"
            />
            <SkeletonKeyword
              animated={false}
              className="scale-75 relative animate-none opacity-60"
            />
          </div>
        )}
        <div className="flex flex-col gap-2 items-center text-center max-w-64 mt-4">
          <p
            className={cn(
              "font-medium text-md text-left text-secondary-900",
              titleStyle,
            )}
          >
            {title}
          </p>
          <p className="text-sm font-normal text-secondary-500">
            {description}
          </p>
          {errorMessage && (
            <p className="text-sm font-normal text-red-400 pb-4">
              {errorMessage}
            </p>
          )}

          {showButton && (
            <Button
              label={
                <div className="flex flex-row items-center gap-2">
                  {iconElement}
                  <p className="text-sm">{label}</p>
                </div>
              }
              onClick={onClick}
              size="small"
              disabled={disabled}
              {...buttonProps}
              analyticsId="button_regenerate_keywords"
            />
          )}
        </div>
      </div>
    </div>
  );
};
