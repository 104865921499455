/* eslint-disable react/no-unescaped-entities */
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { InboxIcon } from "lucide-react";
import { Trans, useTranslation } from "react-i18next";

interface LoginLinkSuccessModalProps {
  email: string;
  open: boolean;

  onClose(): void;
}

export function LoginLinkSuccessModal({
  email,
  open,
  onClose,
}: LoginLinkSuccessModalProps) {
  const { t } = useTranslation("login");

  return (
    <Dialog
      open={open}
      defaultOpen
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onClose();
        }
      }}
    >
      <DialogContent>
        <DialogHeader>
          <div className="relative mb-2">
            <InboxIcon className="h-14 w-14" strokeWidth={0.75} />
            <div className="bg-red-500 h-4 w-4 border-2 border-white rounded-full absolute bottom-1 -right-0" />
          </div>

          <DialogTitle className="text-2xl">
            {t("login:link.success.title")}
          </DialogTitle>
          <DialogDescription className="text-secondary-400 !text-md font-light whitespace-pre">
            <Trans
              i18nKey="login:link.success.description"
              values={{ email }}
              components={{ strong: <span className="font-medium" /> }}
            />
          </DialogDescription>
        </DialogHeader>

        <hr />

        <div className="text-center text-secondary-400 !text-sm font-light">
          <Trans
            i18nKey="login:link.success.help"
            components={{
              button: (
                <DialogClose className="text-primary-500 font-semibold" />
              ),
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
