import React from "react";
import { Link } from "react-router-dom";
import { RoutePath } from "shared/constants";
import { useTranslation } from "react-i18next";
import { AlertOctagon } from "lucide-react";
import { Button } from "components/button";

interface ErrorPageProps {
  title: string;
  message: string;
  backLink?: string;
  onRetry?: () => void;
  error?: Error | string;
}

export const ErrorPage = ({
  title,
  message,
  backLink = "/",
  error,
  onRetry,
}: ErrorPageProps) => {
  const { t } = useTranslation(["error"]);

  return (
    <div className="flex flex-col space-y-10 h-[75vh] flex-1 items-center justify-center text-center max-w-xl mx-auto container">
      <div className="rounded-full bg-icon-error-ring p-3">
        <div className="rounded-full bg-icon-error-background p-2">
          <AlertOctagon className="text-system-error h-8 w-8" />
        </div>
      </div>

      <div className="space-y-2">
        <h1 className="font-semibold text-2xl">{title}</h1>
        <p className="text-secondary-400 font-light whitespace-pre">
          {message}
        </p>
      </div>

      {error && (
        <p className="text-destructive">
          {error instanceof Error ? error.message : error}
        </p>
      )}

      <div className="grid grid-flow-col gap-3">
        <Link
          to={RoutePath.ContactUs}
          target="_blank"
          rel="noopener noreferrer"
          data-testid="support-action"
        >
          <Button
            label={t("error:actions.support")}
            variant="tertiary"
            size="small"
            className="!text-sm"
          />
        </Link>

        {onRetry ? (
          <Button
            label={t("error:actions.reload")}
            variant="primary"
            size="small"
            onClick={onRetry}
            className="!text-sm"
          />
        ) : (
          <Link to={backLink}>
            <Button
              label={t("error:actions.back")}
              variant="primary"
              size="small"
              className="!text-sm font-normal"
            />
          </Link>
        )}
      </div>
    </div>
  );
};
