import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";

import { useOrganisation } from "contexts/OrganisationProvider";
import { useGeneratingWatcher } from "./hooks/useGeneratingWatcher";

export function OrganisationStatusPage() {
  const { t } = useTranslation("settings");
  const { organisation } = useOrganisation();
  const { generatingProducts } = useGeneratingWatcher(organisation?.id);

  const keywords =
    generatingProducts?.filter((p) => p.actions?.keywords?.active) || [];

  const descriptions =
    generatingProducts?.filter((p) => p.actions?.descriptions?.active) || [];

  const thema =
    generatingProducts?.filter((p) => p.actions?.thema?.active) || [];

  return (
    <div className="space-y-6">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>
              {t("settings:organisation.status.table.header.type")}
            </TableHead>
            <TableHead>
              {t("settings:organisation.status.table.header.count")}
            </TableHead>
            <TableHead>
              {t("settings:organisation.status.table.header.products")}
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>
              {t("settings:organisation.status.table.rows.keywords")}
            </TableCell>
            <TableCell>{keywords.length}</TableCell>
            <TableCell>
              <div className="flex flex-col gap-1">
                {keywords.map((product) => (
                  <Link
                    key={product.id}
                    to={`/books/${product.isbn}?tab=keywords`}
                    className="text-sm text-blue-600 hover:underline"
                  >
                    {product.isbn}
                  </Link>
                ))}
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {t("settings:organisation.status.table.rows.thema")}
            </TableCell>
            <TableCell>{thema.length}</TableCell>
            <TableCell>
              <div className="flex flex-col gap-1">
                {thema.map((product) => (
                  <Link
                    key={product.id}
                    to={`/books/${product.isbn}?tab=thema`}
                    className="text-sm text-blue-600 hover:underline"
                  >
                    {product.isbn}
                  </Link>
                ))}
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {t("settings:organisation.status.table.rows.descriptions")}
            </TableCell>
            <TableCell>{descriptions.length}</TableCell>
            <TableCell>
              <div className="flex flex-col gap-1">
                {descriptions.map((product) => (
                  <Link
                    key={product.id}
                    to={`/books/${product.isbn}?tab=description`}
                    className="text-sm text-blue-600 hover:underline"
                  >
                    {product.isbn}
                  </Link>
                ))}
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
