import { Icon } from "components/icon/Icon";
import { cn } from "utils";
import { InfoTooltip } from "components/InfoTooltip";
import { ListItem } from "components/List/ListItem";

interface SelectableListItemProps {
  id: string;
  text: string;
  subtext?: string;
  isSelected: boolean;
  testId: string;
  onSelect: () => void;
  infoText?: string;
  className?: string;
}

export const SelectableListItem = ({
  id,
  text,
  subtext,
  isSelected,
  testId,
  onSelect,
  infoText,
  className,
}: SelectableListItemProps) => {
  return (
    <ListItem
      onClick={onSelect}
      testId={testId}
      className={className}
      aria-selected={isSelected}
    >
      <div className="flex flex-row gap-x-2 min-w-0 flex-1">
        <p className="text-sm text-secondary-900 shrink-0">{text}</p>
        <div className="flex items-center min-w-0 flex-1">
          <p className="text-sm text-secondary-400 truncate">{subtext}</p>
        </div>
        {infoText && <InfoTooltip text={infoText} testId={`info-icon-${id}`} />}
      </div>

      <span
        className={cn(
          "shrink-0 ml-2",
          isSelected ? "text-primary-500" : "text-secondary-400",
        )}
      >
        <Icon
          name={isSelected ? "circle-check" : "circle-plus"}
          aria-label={isSelected ? "Selected" : "Not selected"}
          size="medium"
          data-testid={isSelected ? `check-icon-${id}` : `add-icon-${id}`}
        />
      </span>
    </ListItem>
  );
};
