import React, { useLayoutEffect, useRef, useState } from "react";
import { Badge } from "components/ui/badge";
import { useFormContext } from "react-hook-form";
import { FilterType, SearchQueryFilters } from "hooks/search/types";
import { parseComparisonOperator } from "hooks/search/utils";
import { useTranslation } from "react-i18next";
import { useSearchQuery } from "hooks/search/useSearchQuery";
import { isValidFilter } from "./utils";
import { defaultFilterValues } from "./constants";

export const ActiveFilterBadge = ({ filterName }: { filterName: string }) => {
  const { watch, setValue } = useFormContext();
  const { removeQueryParameter } = useSearchQuery();
  const { t } = useTranslation(["general"]);
  const filter = watch(`filters.${filterName}`);
  const isFilterActive = isValidFilter(filter);

  const badgeRef = useRef<HTMLDivElement>(null);
  const clearButtonRef = useRef<HTMLDivElement>(null);
  const [maxWidth, setMaxWidth] = useState<number | undefined>(undefined);

  useLayoutEffect(() => {
    if (badgeRef.current && clearButtonRef.current) {
      const badgeWidth = badgeRef.current.offsetWidth;
      const clearButtonWidth = Math.max(75, badgeWidth);
      setMaxWidth(clearButtonWidth);
    }
  }, [filter]);

  if (!isFilterActive) return null;

  const filterLabel = (() => {
    if (filter.type === FilterType.NUMERIC) {
      return `${parseComparisonOperator(filter.comparisonOperator)} ${filter.value}`;
    }

    if (filter.type === FilterType.DATE_RANGE && filter.range) {
      if (filter.range.from !== filter.range.to) {
        const fromYear = filter.range.from.split("-")[0];
        const toYear = filter.range.to.split("-")[0];

        if (fromYear === toYear) {
          return `${filter.range.from.replace(/-/g, ".")} - ${filter.range.to.slice(5, filter.range.to.length).replace(/-/g, ".")}`;
        }
        return `${filter.range.from.replace(/-/g, ".")} - ${filter.range.to.replace(/-/g, ".")}`;
      }
      return filter.range.from.replace(/-/g, ".");
    }

    return filter.value;
  })();

  const resetFilter = () => {
    if (filterName in defaultFilterValues) {
      setValue(
        `filters.${filterName}`,
        defaultFilterValues[filterName as keyof typeof defaultFilterValues],
      );
    }
    removeQueryParameter(filterName as keyof SearchQueryFilters);
  };

  return (
    <div
      className="p-2 ml-2 flex justify-end"
      style={{ width: maxWidth, minWidth: 75 }}
    >
      <div ref={badgeRef} className="group-hover:hidden block">
        <Badge
          variant="secondary"
          className="ml-2 min-w-4 px-2 py-[2px] whitespace-nowrap text-secondary-600 text-2xs"
          aria-label={filterLabel}
        >
          {filterLabel}
        </Badge>
      </div>
      <div ref={clearButtonRef} className="group-hover:block hidden">
        <Badge
          variant="secondary"
          onClick={resetFilter}
          role="button"
          className="ml-2 min-w-4 cursor-pointer whitespace-nowrap text-2xs"
          aria-label={t("general:buttons.clear", {
            filter: filterLabel,
          })}
        >
          {t("general:buttons.clear")}
        </Badge>
      </div>
    </div>
  );
};
