import { Client } from "@hey-api/client-fetch";
import {
  addOrganisationUsers,
  deleteOrganisationUser,
  getOrganisationFileReportRoute,
  getOrganisationFilesRoute,
  OrganisationUsersCreateRequest,
  patchUser,
  postOrganisationFileRoute,
  UserRole,
  zAddOrganisationUsersResponse,
  zDeleteOrganisationUserResponse,
  zGetOrganisationFileReportRouteResponse,
  zGetOrganisationFilesRouteResponse,
  zPatchUserResponse,
  zPostOrganisationFileRouteResponse,
} from "__generated__/api";
import { parseResult } from "../parseHelper";

export class OrganisationsApi {
  constructor(private client: Client) {}

  async getFiles(organisationId: string) {
    const response = await getOrganisationFilesRoute({
      path: { identifier: organisationId },
      client: this.client,
    });

    const result = parseResult(
      zGetOrganisationFilesRouteResponse,
      response,
      {},
    );

    return result?.data;
  }

  async addFile(organisationId: string, file: File) {
    const response = await postOrganisationFileRoute({
      path: { identifier: organisationId, file_name: file.name },
      query: { no_sign: true },
      body: { upload: file },
      client: this.client,
    });

    const result = parseResult(
      zPostOrganisationFileRouteResponse,
      response,
      {},
    );

    return result?.data;
  }

  async getReport(organisationId: string, fileName: string) {
    const response = await getOrganisationFileReportRoute({
      path: { identifier: organisationId, file_name: fileName },
      client: this.client,
    });

    const result = parseResult(
      zGetOrganisationFileReportRouteResponse,
      response,
      {},
    );

    return result?.data;
  }

  async addUsers(organisationId: string, data: OrganisationUsersCreateRequest) {
    const response = await addOrganisationUsers({
      path: {
        identifier: organisationId,
      },
      body: data,
      client: this.client,
    });

    const result = parseResult(zAddOrganisationUsersResponse, response, data);

    return result?.data;
  }

  async removeUser(organisationId: string, userId: string) {
    const response = await deleteOrganisationUser({
      path: {
        identifier: organisationId,
        useridentifier: userId,
      },
      client: this.client,
    });

    const result = parseResult(zDeleteOrganisationUserResponse, response, {
      organisationId,
      userId,
    });

    return result?.data;
  }

  async updateUserRole(organisationId: string, userId: string, role: UserRole) {
    const response = await patchUser({
      path: {
        identifier: organisationId,
        useridentifier: userId,
      },
      body: {
        roles: [role],
      },
      client: this.client,
    });

    const result = parseResult(zPatchUserResponse, response, {
      organisationId,
      userId,
    });

    return result?.data;
  }
}
