import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "integrations/firebase/firestore";
import { FirestoreProject } from "reedy-data/models";

export type { FirestoreProject };
export interface IProject extends Omit<FirestoreProject, "isbns"> {
  id: string;
  productIds: string[];
}

const ProjectConverter = {
  toFirestore(project: Partial<IProject>) {
    const { createdAt, productIds, ...rest } = project;
    const newProject = {
      ...rest,
      ...(createdAt ? { createdAt: Timestamp.fromDate(createdAt) } : {}),
      ...(productIds ? { isbns: productIds } : {}),
    };
    return newProject;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): IProject {
    const documentData = snapshot.data();
    const project = {
      ...documentData,
      createdAt: documentData.createdAt.toDate(),
      id: snapshot.id,
      productIds: documentData.isbns,
    } as IProject;
    return project;
  },
};

export const getProjectsCollection = () =>
  collection(db, "projects").withConverter(ProjectConverter);

type PaginationOptions = {
  pageSize: number;
  currentPage: number;
};

export const getPaginatedProjects = async (
  organisationId: string,
  userId: string | null,
  { pageSize, currentPage }: PaginationOptions,
) => {
  const baseQuery = query(
    getProjectsCollection(),
    where("organisation.id", "==", organisationId),
    ...(userId ? [where("user.uid", "==", userId)] : []),
    orderBy("createdAt", "desc"),
    limit(pageSize * (currentPage + 3)),
  );

  const snapshot = await getDocs(baseQuery);
  const currentPageProjects = snapshot.docs.map((document) => ({
    ...document.data(),
    id: document.id,
  })) as IProject[];
  return currentPageProjects;
};

export const getProject = async (projectId: string) => {
  const projectDoc = await getDoc(doc(getProjectsCollection(), projectId));
  if (!projectDoc.exists()) {
    throw new Error("Project not found");
  }
  return {
    ...projectDoc.data(),
    id: projectDoc.id,
  } as IProject;
};

export type UpdateProjectFields = Partial<
  Pick<IProject, "productIds" | "name">
>;

export type CreateProjectFields = Omit<IProject, "id">;

export const addProject = async (project: CreateProjectFields) =>
  addDoc(getProjectsCollection(), project);

export const updateProject = async (
  projectId: string,
  projectFields: UpdateProjectFields,
) => {
  const projectRef = doc(getProjectsCollection(), projectId);
  await updateDoc(projectRef, ProjectConverter.toFirestore(projectFields));
};
