import { useNavigate, useLocation } from "react-router-dom";
import ModalOverlayLoadingSpinner from "components/loading/ModalOverlayLoadingSpinner";
import { useTranslation } from "react-i18next";
import { useProduct } from "contexts/ProductContext";
import { useProject } from "contexts/ProjectContext";
import { Button } from "components/button";
import { RoutePath } from "shared/constants";
import { useEffect, useState } from "react";
import { ProductNavigationBar } from "./components/ProductNavigationBar";
import { NoProductFound } from "./components/NoProductFound";
import { ErrorPage } from "../../components/errors/ErrorPage";

export const ProductDetails: React.FC = () => {
  const { t } = useTranslation(["productDetails"]);
  const navigate = useNavigate();
  const location = useLocation();
  const [fromUrl, setFromUrl] = useState<string>();

  const { product, loading, error } = useProduct();
  const { project } = useProject();

  useEffect(() => {
    const { state } = location;

    if (state?.from) {
      setFromUrl(state.from);
    }
  }, [location]);

  const handleGoBack = (defaultPath: string) => {
    navigate(fromUrl || defaultPath);
  };
  if (error) {
    return (
      <ErrorPage
        title={t("productDetails:notFound.title")}
        message={t("productDetails:notFound.message")}
        backLink={project ? `/projects/${project.id}` : RoutePath.Books}
      />
    );
  }

  if (!product && !loading) {
    return <NoProductFound />;
  }

  return (
    <>
      {project ? (
        <Button
          variant="ghost"
          label={t("productDetails:header.overview")}
          icon="arrow-left"
          className="-ml-4"
          onClick={() => handleGoBack(`/projects/${project.id}`)}
          analyticsId="button_goto_overview"
          data-testid="overview-link"
        />
      ) : (
        <Button
          variant="ghost"
          label={t("productDetails:header.books")}
          icon="arrow-left"
          className="-ml-4"
          onClick={() => handleGoBack(RoutePath.Books)}
          analyticsId="button_goto_overview"
          data-testid="books-link"
        />
      )}

      <ModalOverlayLoadingSpinner isLoading={loading} />

      <ProductNavigationBar />
    </>
  );
};
