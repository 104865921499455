import { Input } from "components/ui/input";
import { cn } from "utils";
import { Button } from "components/button";
import { useCallback } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { logError } from "shared/services/ErrorReporting";
import { useAuth } from "contexts/AuthContext";
import { useAnalytics } from "contexts/AnalyticsContext";
import { toast } from "utils/toast";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip";
import { LoginLinkSuccessModal } from "./LoginLinkSuccessModal";

const schema = yup
  .object({ email: yup.string().email().required() })
  .required();

type LoginFormProps = {
  isButtonDisabled: boolean;
};

export function LoginForm({ isButtonDisabled }: LoginFormProps) {
  const { t } = useTranslation(["general", "login"]);

  const { sendLoginLink } = useAuth();
  const { gaEvent } = useAnalytics();

  const { handleSubmit, register, formState, reset, setError, watch } = useForm(
    {
      defaultValues: { email: "" },
      resolver: yupResolver(schema),
    },
  );

  const loginWithLink = useCallback(
    async (values: { email: string }) => {
      try {
        await sendLoginLink(values.email);
        gaEvent({ type: "login_request", payload: { method: "link" } });
      } catch (error) {
        logError(error);

        toast.error(
          `${t("login:error.loginFailed")}: ${(error as Error).message}`,
        );

        setError("root", error as Error);
      }
    },
    [sendLoginLink, gaEvent, t, setError],
  );

  const email = watch("email");

  return (
    <form className="grid gap-4" onSubmit={handleSubmit(loginWithLink)}>
      <div className="space-y-1.5">
        <Input
          placeholder={t("login:form.email.placeholder")}
          className={cn(
            "placeholder:text-sm text-sm",
            formState.errors.email?.message &&
              "border border-system-error ring-2 ring-system-error/25",
          )}
          {...register("email", { required: true })}
        />

        <div
          className={cn(
            "text-xs text-secondary-400 font-normal italic",
            formState.errors.email && "text-system-error",
          )}
        >
          {formState.errors.email
            ? t("login:form.email.error")
            : t("login:form.email.hint")}
        </div>
      </div>

      <Tooltip>
        <TooltipTrigger asChild>
          <div>
            <Button
              label={t("login:form.buttons.submit")}
              variant="primary"
              type="submit"
              size="small"
              disabled={
                formState.isSubmitting ||
                (!formState.isValid && formState.isSubmitted) ||
                isButtonDisabled
              }
              className="!text-sm font-normal w-full"
              analyticsId="link-login-button"
            />
          </div>
        </TooltipTrigger>
        {isButtonDisabled && (
          <TooltipContent>
            <p>{t("login:agreement-required")}</p>
          </TooltipContent>
        )}
      </Tooltip>

      <LoginLinkSuccessModal
        email={email}
        open={formState.isSubmitSuccessful}
        onClose={() => reset({ email })}
      />
    </form>
  );
}
