import { Info } from "components/icon/Info";
import { useTranslation } from "react-i18next";

export function ContactUsSuccess() {
  const { t } = useTranslation(["contactUs"]);

  return (
    <div className="w-full min-h-[50vh] pt-24 flex justify-center items-center">
      <div className="pb-4 flex flex-col items-center text-center gap-6 max-w-lg">
        <div className="mb-4">
          <Info className="h-12 w-12 p-3" />
        </div>

        <p className="pb-1 text-2xl font-semibold">{t("success.title")}</p>

        <p className="text-md text-secondary-400 font-light">
          {t("success.description")}
        </p>
      </div>
    </div>
  );
}
