import { Icon } from "components/icon/Icon";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipArrow,
} from "components/ui/tooltip";
import { ReactNode } from "react";
import { cn } from "utils";

interface InfoTooltipProps {
  text?: string;
  testId?: string;
  side?: "top" | "bottom";
  children?: ReactNode;
  triggerClassName?: string;
  contentClassName?: string;
}

export const InfoTooltip = ({
  text,
  testId,
  triggerClassName = "",
  side = "bottom",
  children,
  contentClassName = "",
}: InfoTooltipProps) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <span
          className={cn(
            "text-secondary-400 hover:text-secondary-500 shrink-0",
            "opacity-0 group-hover:opacity-100 transition-opacity duration-200",
            triggerClassName,
          )}
          data-testid={testId}
        >
          <Icon name="info" size="medium" />
        </span>
      </TooltipTrigger>
      <TooltipContent
        side={side}
        className={cn(
          "text-left shadow-tooltip text-neutral-600 whitespace-normal z-10 border-none leading-relaxed max-w-xs",
          contentClassName,
        )}
        sideOffset={6}
      >
        <TooltipArrow
          className="fill-white drop-shadow-sm"
          width={12}
          height={6}
        />
        {children || <p className="text-sm">{text}</p>}
      </TooltipContent>
    </Tooltip>
  );
};
