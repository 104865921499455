import { useCallback } from "react";
import { SelectableListItem } from "components/List/SelectableListItem";
import { FirestoreProductSubject } from "__generated__/models";
import { useSubjects } from "../../hooks/useSubjects";

interface SubjectItemProps {
  subject: FirestoreProductSubject;
  isSelected: boolean;
  testId: string;
}

export const SubjectItem = ({
  subject,
  isSelected,
  testId,
}: SubjectItemProps) => {
  const { handleAddSubject } = useSubjects();

  const handleClicked = useCallback(() => {
    handleAddSubject(subject);
  }, [handleAddSubject, subject]);

  if (!subject.subjectCode) {
    return null;
  }

  return (
    <SelectableListItem
      id={subject.subjectCode}
      text={subject.subjectCode}
      subtext={subject.subjectHeadingText ?? ""}
      isSelected={isSelected}
      testId={testId}
      onSelect={handleClicked}
      infoText={subject.reasoning ?? undefined}
    />
  );
};
