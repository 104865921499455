import { useProject } from "hooks/useProject";
import { IProduct, IProject } from "integrations/firebase/collections";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RoutePath } from "shared/constants";
import { useAnalytics } from "contexts/AnalyticsContext";
import { useProductActions } from "hooks/useProductActions";
import { ProjectType } from "reedy-data/models";

interface IProjectDetailsContext
  extends Pick<
    ReturnType<typeof useProductActions>,
    "generateKeywords" | "generateDescriptions"
  > {
  exportModalOpen: boolean;
  setExportModalOpen: Dispatch<SetStateAction<boolean>>;
  productsToExport: IProduct[];
  setProductsToExport: (prodToExport: IProduct[]) => void;
  handleBack: () => void;
  isTrendProject: boolean | undefined;
  onRowClickCallback: (row: IProduct) => void;
  isLoadingRead: boolean;
  isLoadingUpdate: boolean;
  errorRead: Error | undefined;
  errorUpdate: Error | undefined;
  updateProjectName: (idProject: string, name: string) => void;
  products: IProduct[] | undefined;
  isLoadingGenerate: boolean;
  errorGenerate: Error | undefined;
  isLoadingProducts: boolean;
  errorProductsRead: Error | undefined;
  errorProjectRead: string | undefined;
  project?: IProject | null;
  idProject: string;
  product: IProduct | null;
}

export const ProjectDetailsContext = createContext<IProjectDetailsContext>(
  undefined as never,
);

export const useProjectDetails = () => useContext(ProjectDetailsContext);

export const ProjectDetailsContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [product, setProduct] = useState<IProduct | null>(null);
  const { gaEvent } = useAnalytics();
  const { idProject = "0", idProduct = "0" } = useParams();
  const [productsToExport, setProductsToExport] = useState([] as IProduct[]);

  const navigate = useNavigate();

  const {
    project,
    isLoadingRead,
    isLoadingUpdate,
    errorProjectRead,
    errorRead,
    errorUpdate,
    updateProjectName,
    products,
    isLoadingProducts,
    errorProductsRead,
  } = useProject(idProject);

  const {
    generateKeywords,
    generateDescriptions,
    isLoadingGenerate,
    errorGenerate,
  } = useProductActions();

  useEffect(() => {
    const paramProduct =
      products?.find((prod) => prod.id === idProduct) ?? null;
    if (!paramProduct) {
      setProduct(null);
    }
    if (product?.id !== paramProduct?.id) {
      setProduct(paramProduct);
    }
  }, [idProduct, product?.id, products]);

  const handleBack = useCallback(() => {
    navigate(RoutePath.Projects);
    gaEvent({
      type: "view_item_list",
      payload: {
        item_list_name: "projects",
        item_list_id: project?.id,
        item_list_category: project?.type,
      },
    });
  }, [gaEvent, navigate, project?.id, project?.type]);

  const isTrendProject =
    project?.type &&
    project?.type in [ProjectType.GOOGLE_TRENDS, ProjectType.AMAZON_TRENDS];

  const onRowClickCallback = useCallback(
    (row: IProduct) => {
      if (!idProject) {
        return;
      }

      navigate(
        RoutePath.ProductDetails.replace(":idProject", idProject).replace(
          ":idProduct",
          row.id,
        ),
      );
      gaEvent({
        type: "view_item",
        payload: {
          item_id: row.id,
          item_name: row.title,
          item_category: "product",
          author: row.author,
          isbn: row.isbn,
          title: row.title,
        },
      });
    },
    [gaEvent, idProject, navigate],
  );

  const changeExports = useCallback(
    (prodToExport: IProduct[]) => setProductsToExport(prodToExport),
    [],
  );

  const contextValue: IProjectDetailsContext = useMemo(
    () => ({
      exportModalOpen,
      setExportModalOpen,
      productsToExport,
      setProductsToExport: changeExports,
      handleBack,
      isTrendProject,
      onRowClickCallback,
      isLoadingRead,
      isLoadingUpdate,
      errorRead,
      errorUpdate,
      updateProjectName,
      products,
      generateKeywords,
      generateDescriptions,
      isLoadingGenerate,
      errorGenerate,
      isLoadingProducts,
      errorProductsRead,
      errorProjectRead,
      project,
      idProject,
      product,
    }),
    [
      exportModalOpen,
      productsToExport,
      changeExports,
      handleBack,
      isTrendProject,
      onRowClickCallback,
      isLoadingRead,
      isLoadingUpdate,
      errorRead,
      errorUpdate,
      updateProjectName,
      products,
      generateKeywords,
      generateDescriptions,
      isLoadingGenerate,
      errorGenerate,
      isLoadingProducts,
      errorProjectRead,
      errorProductsRead,
      project,
      idProject,
      product,
    ],
  );

  return (
    <ProjectDetailsContext.Provider value={contextValue}>
      {children}
    </ProjectDetailsContext.Provider>
  );
};
