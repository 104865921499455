import { query, where } from "firebase/firestore";
import { getProductsCollection } from "integrations/firebase/collections";
import { useCollectionData } from "react-firebase-hooks/firestore";

export const useGeneratingWatcher = (organisationId: string | undefined) => {
  const [generatingProducts = []] = useCollectionData(
    organisationId
      ? query(
          getProductsCollection(),
          where("organisation.id", "==", organisationId),
          where("actions.active", "==", true),
        )
      : undefined,
  );

  return {
    generatingProducts,
  };
};
