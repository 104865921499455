import { Button } from "components/button/Button";
import { Input } from "components/ui/input";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import {
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "integrations/firebase/storage";
import { logError } from "shared/services/ErrorReporting";
import { ProfilePictureUpload } from "../../user/ProfilePictureUpload";

interface FormValues {
  email: string;
  photoUrl: string;
  firstName: string;
  lastName: string;
}

interface ProfileFormProps {
  email: string;
  userId: string;
  photoUrl?: string;
  firstName?: string;
  lastName?: string;
  updateUserProfile: (profile: Omit<FormValues, "email">) => Promise<void>;
}

export function ProfileForm({
  email,
  userId,
  photoUrl,
  firstName,
  lastName,
  updateUserProfile,
}: ProfileFormProps) {
  const { t } = useTranslation("profile");

  const formSchema = z.object({
    firstName: z
      .string()
      .trim()
      .min(1, { message: t("profile:setup.form.firstName.required") }),
    lastName: z
      .string()
      .trim()
      .min(1, { message: t("profile:setup.form.lastName.required") }),
    email: z
      .string()
      .trim()
      .min(1, { message: t("profile:setup.form.email.required") }),
    photoUrl: z.string().optional(),
  });

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      email: email ?? "",
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      photoUrl: photoUrl ?? "",
    },
  });

  const updateProfilePicture = async (file: File) => {
    try {
      const storageRef = ref(storage, `users/${userId}/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadUrl = await getDownloadURL(storageRef);

      form.setValue("photoUrl", downloadUrl);
      form.clearErrors("photoUrl");
    } catch (error) {
      logError(`Error uploading profile picture: ${error}`);
      form.setError("photoUrl", {
        message: t("profile:setup.form.photoUrl.uploadError"),
      });
    }
  };

  const onSave = async (values: FormValues) => {
    await updateUserProfile({
      firstName: values.firstName,
      lastName: values.lastName,
      photoUrl: values.photoUrl,
    });
  };

  return (
    <Form {...form}>
      <form
        className="grid gap-y-6 gap-x-8"
        onSubmit={form.handleSubmit(onSave)}
      >
        <div className="mx-auto">
          <FormField
            control={form.control}
            name="photoUrl"
            render={() => (
              <FormItem>
                <FormControl>
                  <ProfilePictureUpload
                    photoUrl={photoUrl}
                    onUpdate={updateProfilePicture}
                  />
                </FormControl>
                <FormMessage className="text-xs text-red-400" />
              </FormItem>
            )}
          />
        </div>
        <div className="grid grid-cols-2 gap-8">
          <div className="space-y-2">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-sm">
                    {t("profile:setup.form.firstName.label")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      className="text-sm"
                      placeholder={t(
                        "profile:setup.form.firstName.placeholder",
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="text-xs text-red-400" />
                </FormItem>
              )}
            />
          </div>

          <div className="space-y-2">
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-sm">
                    {t("profile:setup.form.lastName.label")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      className="text-sm"
                      placeholder={t("profile:setup.form.lastName.placeholder")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="text-xs text-red-400" />
                </FormItem>
              )}
            />
          </div>
        </div>

        <div className="space-y-2">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-sm">
                  {t("profile:setup.form.email.label")}
                </FormLabel>
                <FormControl>
                  <Input
                    className="text-sm"
                    disabled
                    placeholder={t("profile:setup.form.email.placeholder")}
                    {...field}
                  />
                </FormControl>
                <FormMessage className="text-xs text-red-400" />
              </FormItem>
            )}
          />
        </div>

        <Button
          label={t("profile:setup.form.submit")}
          type="submit"
          disabled={!form.formState.isValid}
        />
      </form>
    </Form>
  );
}
