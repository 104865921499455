import React from "react";
import { useTranslation } from "react-i18next";
import { ExpandableInfoTooltip } from "components/InfoTooltip/ExpandableInfoTooltip";
import { Textarea } from "components/ui/textarea";
import { Label } from "components/ui/label";

export interface DetailsGenerateBookDescriptionProps {
  specialDetails: string | null;
  setSpecialDetails: (specialDetails: string | null) => void;
}

export const AdditionalInfo: React.FC<DetailsGenerateBookDescriptionProps> = ({
  specialDetails,
  setSpecialDetails,
}) => {
  const { t } = useTranslation(["productDetails"]);

  return (
    <div className="grid gap-3">
      <Label className="flex items-center gap-2 text-sm">
        {t(
          "productDetails:bookDescription.tabGenerate.detailsGenerate.special",
        )}

        <ExpandableInfoTooltip
          align="start"
          text={t(
            "productDetails:bookDescription.tabGenerate.detailsGenerate.specialTooltip",
          )}
          moreText={
            <>
              <p className="my-2">
                {t(
                  "productDetails:bookDescription.tabGenerate.detailsGenerate.specialTooltipMore",
                )}
              </p>
              <ul className="list-disc list-outside pl-4">
                {t(
                  "productDetails:bookDescription.tabGenerate.detailsGenerate.specialTooltipMoreList",
                )
                  .split("\n")
                  .map((item) => (
                    <li className="whitespace-pre-line leading-4" key={item}>
                      {item}
                    </li>
                  ))}
              </ul>
            </>
          }
          contentClassName="font-light text-xs text-center px-5 whitespace-pre-line"
        />
      </Label>

      <Textarea
        rows={7}
        className="placeholder:text-secondary-400 placeholder:text-sm text-sm"
        value={specialDetails || ""}
        placeholder={t(
          "productDetails:bookDescription.tabGenerate.detailsGenerate.specialPlaceholder",
        )}
        role="textbox"
        onChange={(event) => setSpecialDetails(event.target.value)}
        data-testid="special-details-textfield"
      />
    </div>
  );
};
